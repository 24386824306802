import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  File: any;
  ISO8601DateTime: any;
  JSON: any;
};

export type Admin = {
  __typename?: 'Admin';
  canEdit: Scalars['Boolean'];
  canManage: Scalars['Boolean'];
  canViewFinance: Scalars['Boolean'];
  canViewJobs: Scalars['Boolean'];
  canViewUsers: Scalars['Boolean'];
  email: Scalars['String'];
  id: Scalars['ID'];
  isSuper: Scalars['Boolean'];
  role: Scalars['String'];
  supervisor: Scalars['Boolean'];
};

export type AdminLog = {
  __typename?: 'AdminLog';
  admin: Admin;
  createdAt: Scalars['ISO8601DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  targetId?: Maybe<Scalars['ID']>;
  targetType?: Maybe<Scalars['String']>;
};

export type AssignTeamInput = {
  id: Scalars['ID'];
  tournamentTeamIds: Array<Scalars['ID']>;
};

/** Autogenerated return type of AssignTeam */
export type AssignTeamPayload = {
  __typename?: 'AssignTeamPayload';
  errors?: Maybe<Array<Error>>;
  tournamentRoom?: Maybe<TournamentRoom>;
};

/** Bank Info */
export type BankInfo = {
  __typename?: 'BankInfo';
  bankAccountNo: Scalars['String'];
  bankName: Scalars['String'];
  country: Scalars['String'];
  ic: Scalars['String'];
  icImage?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  pilot?: Maybe<Pilot>;
  rejectReason?: Maybe<Scalars['String']>;
  selfie?: Maybe<Scalars['String']>;
  state: BankInfoState;
  updatedAt: Scalars['ISO8601DateTime'];
  user?: Maybe<User>;
};

export enum BankInfoState {
  Approved = 'approved',
  PendingResubmission = 'pending_resubmission',
  Submitted = 'submitted'
}

/** Battle */
export type Battle = {
  __typename?: 'Battle';
  battleId?: Maybe<Scalars['String']>;
  camps?: Maybe<Array<Camp>>;
  createdAt: Scalars['ISO8601DateTime'];
  dataId?: Maybe<Scalars['Int']>;
  gameTime?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  paused?: Maybe<Scalars['Boolean']>;
  roomName: Scalars['String'];
  startTime?: Maybe<Scalars['ISO8601DateTime']>;
  state: BattleState;
  stateLeftTime?: Maybe<Scalars['String']>;
  tournamentRoom: TournamentRoom;
  updatedAt: Scalars['ISO8601DateTime'];
  winCampId?: Maybe<Scalars['Int']>;
};

export enum BattleState {
  Adjust = 'adjust',
  Ban = 'ban',
  CampFirstSubmitted = 'camp_first_submitted',
  CampSecondSubmitted = 'camp_second_submitted',
  ConfirmCompleted = 'confirm_completed',
  End = 'end',
  Flagged = 'flagged',
  Init = 'init',
  Loading = 'loading',
  Pending = 'pending',
  Pick = 'pick',
  Play = 'play',
  Started = 'started',
  Unscanned = 'unscanned'
}

/** Booking */
export type Booking = {
  __typename?: 'Booking';
  createdAt: Scalars['ISO8601DateTime'];
  gameId: Scalars['ID'];
  id: Scalars['ID'];
  isBuyer: Scalars['Boolean'];
  isCreator: Scalars['Boolean'];
  matchMaking: MatchMaking;
  owner: User;
  rating?: Maybe<Scalars['Int']>;
  ratingRemark?: Maybe<Scalars['String']>;
  reporter?: Maybe<User>;
  reportReason?: Maybe<Scalars['String']>;
  state: BookingState;
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
};

/** Booking include counter */
export type BookingCount = {
  __typename?: 'BookingCount';
  count: Scalars['Int'];
  data: Array<Booking>;
};

export enum BookingState {
  Cancelled = 'cancelled',
  Completed = 'completed',
  ConfirmCompleted = 'confirm_completed',
  Pending = 'pending',
  Rated = 'rated',
  Refunded = 'refunded',
  Reported = 'reported',
  Reviewed = 'reviewed',
  Started = 'started'
}

/** Camp */
export type Camp = {
  __typename?: 'Camp';
  battle: Battle;
  campId?: Maybe<Scalars['Int']>;
  campPlayers?: Maybe<Array<CampPlayer>>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  killLord?: Maybe<Scalars['Int']>;
  killTower?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  teamName?: Maybe<Scalars['String']>;
  teamSimpleName?: Maybe<Scalars['String']>;
  totalMoney?: Maybe<Scalars['String']>;
  tournamentTeam?: Maybe<TournamentTeam>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Camp Player */
export type CampPlayer = {
  __typename?: 'CampPlayer';
  assistNum?: Maybe<Scalars['Int']>;
  avgFps?: Maybe<Scalars['Int']>;
  banHeroId?: Maybe<Scalars['Int']>;
  banning?: Maybe<Scalars['Boolean']>;
  bigReconnect?: Maybe<Scalars['Int']>;
  camp?: Maybe<Camp>;
  clientIp?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  damageEffect?: Maybe<Scalars['Int']>;
  damagePercent?: Maybe<Scalars['Int']>;
  dead?: Maybe<Scalars['Boolean']>;
  deadNum?: Maybe<Scalars['Int']>;
  equipList?: Maybe<Array<Scalars['String']>>;
  exp?: Maybe<Scalars['Int']>;
  gold?: Maybe<Scalars['Int']>;
  goldMap?: Maybe<Array<Scalars['String']>>;
  heroId?: Maybe<Scalars['Int']>;
  hitRate?: Maybe<Scalars['String']>;
  hurtPercent?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  judger?: Maybe<Scalars['Boolean']>;
  kda?: Maybe<Scalars['Int']>;
  killNum?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  majorLeftTime?: Maybe<Scalars['Int']>;
  mapPos?: Maybe<Array<Scalars['String']>>;
  minDamage?: Maybe<Scalars['Int']>;
  minFps?: Maybe<Scalars['Int']>;
  minMoney?: Maybe<Scalars['Int']>;
  moneyPercent?: Maybe<Scalars['Int']>;
  moveLag?: Maybe<Scalars['Int']>;
  mvp?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  offeredRate?: Maybe<Scalars['Int']>;
  picking?: Maybe<Scalars['Boolean']>;
  pos?: Maybe<Scalars['ID']>;
  reviveLeftTime?: Maybe<Scalars['Int']>;
  roleId?: Maybe<Scalars['Int']>;
  runeId?: Maybe<Scalars['Int']>;
  runeMap?: Maybe<Array<Scalars['JSON']>>;
  score?: Maybe<Scalars['String']>;
  skillId?: Maybe<Scalars['Int']>;
  skillLeftTime?: Maybe<Scalars['Int']>;
  skillUseTimes?: Maybe<Scalars['Int']>;
  skinId?: Maybe<Scalars['Int']>;
  smallReconnect?: Maybe<Scalars['Int']>;
  teamId?: Maybe<Scalars['ID']>;
  teamName?: Maybe<Scalars['String']>;
  teamSimpleName?: Maybe<Scalars['String']>;
  timeDelay?: Maybe<Scalars['Int']>;
  totalDamage?: Maybe<Scalars['Int']>;
  totalDamageTower?: Maybe<Scalars['Int']>;
  totalHeal?: Maybe<Scalars['Int']>;
  totalHurt?: Maybe<Scalars['Int']>;
  totalMoney?: Maybe<Scalars['Int']>;
  towerDamagePercent?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['ISO8601DateTime'];
  xpm?: Maybe<Scalars['Int']>;
  zoneId?: Maybe<Scalars['ID']>;
};

export type CancelTournamentRoomInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of CancelTournamentRoom */
export type CancelTournamentRoomPayload = {
  __typename?: 'CancelTournamentRoomPayload';
  errors?: Maybe<Array<Error>>;
  tournamentRoom?: Maybe<TournamentRoom>;
};

/** Career Highlight */
export type CareerHighlight = {
  __typename?: 'CareerHighlight';
  id?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
};

/** Cart */
export type Cart = {
  __typename?: 'Cart';
  orders: Array<Order>;
  totalAmount: Scalars['Float'];
};

/** Category */
export type Category = {
  __typename?: 'Category';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Chatroom */
export type Chatroom = {
  __typename?: 'Chatroom';
  admin?: Maybe<User>;
  avatarUrl?: Maybe<Scalars['String']>;
  chatroomHolder: ChatroomHolder;
  clan?: Maybe<Clan>;
  combatRoom?: Maybe<CombatRoom>;
  createdAt: Scalars['ISO8601DateTime'];
  gameBundle?: Maybe<GameBundle>;
  id: Scalars['ID'];
  lastMessage?: Maybe<Message>;
  messages: Array<Message>;
  messagesCount: Scalars['Int'];
  name: Scalars['String'];
  partneringRequest?: Maybe<PartneringRequest>;
  pilot?: Maybe<User>;
  scrim?: Maybe<Scrim>;
  team?: Maybe<Team>;
  unreadCount: Scalars['Int'];
  updatedAt: Scalars['ISO8601DateTime'];
  user?: Maybe<User>;
  users: Array<User>;
};

/** Chatroom Holder */
export type ChatroomHolder = Clan | CombatRoom | GameBundle | PartneringRequest | Scrim | Team | User;

/** Clan */
export type Clan = {
  __typename?: 'Clan';
  active: Scalars['Boolean'];
  avatarUrl: Scalars['String'];
  chatroomId: Scalars['ID'];
  countryCode: Scalars['String'];
  coverPictureUrl: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  featured: Scalars['Boolean'];
  followed: Scalars['Boolean'];
  followersCount: Scalars['Int'];
  id: Scalars['ID'];
  introText?: Maybe<Scalars['String']>;
  isAdmin: Scalars['Boolean'];
  joined: Scalars['Boolean'];
  liked: Scalars['Boolean'];
  likesCount: Scalars['Int'];
  memberLimit?: Maybe<Scalars['Int']>;
  members: Array<User>;
  membersCount?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  onlineCount: Scalars['Int'];
  owner: User;
  postsCount: Scalars['Int'];
  public: Scalars['Boolean'];
  rules?: Maybe<Scalars['String']>;
  targetId?: Maybe<Scalars['ID']>;
  targetType?: Maybe<Scalars['String']>;
  verified: Scalars['Boolean'];
};


/** Clan */
export type ClanMembersArgs = {
  searchUser?: Maybe<Scalars['String']>;
};

/** Club */
export type Club = {
  __typename?: 'Club';
  beStar: Scalars['Boolean'];
  clansCount: Scalars['Int'];
  countryCode?: Maybe<Scalars['String']>;
  coverPictureUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  followed: Scalars['Boolean'];
  followersCount: Scalars['Int'];
  games?: Maybe<Array<Game>>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isManager: Scalars['Boolean'];
  liked: Scalars['Boolean'];
  likesCount: Scalars['Int'];
  manager: User;
  name: Scalars['String'];
  postsCount: Scalars['Int'];
  tagName?: Maybe<Scalars['String']>;
  teamCount: Scalars['Int'];
  teams: Array<Team>;
  themeColor?: Maybe<Scalars['String']>;
  tier?: Maybe<ClubTier>;
  verified: Scalars['Boolean'];
};

export enum ClubTier {
  Amateur = 'amateur',
  Professional = 'professional',
  SemiPro = 'semi_pro'
}

export enum CodeState {
  CodeApproved = 'code_approved',
  CodeSubmitted = 'code_submitted',
  CodeUnverified = 'code_unverified',
  PendingCodeResubmission = 'pending_code_resubmission'
}

/** Combat Queue */
export type CombatQueue = {
  __typename?: 'CombatQueue';
  combatRoom?: Maybe<CombatRoom>;
  combatTeam: CombatTeam;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  score: Scalars['Int'];
  state: CombatQueueState;
};

export enum CombatQueueState {
  Available = 'available',
  Cancelled = 'cancelled',
  Cooldowned = 'cooldowned',
  Expired = 'expired',
  Matched = 'matched',
  Pending = 'pending'
}

/** Combat Room */
export type CombatRoom = {
  __typename?: 'CombatRoom';
  accepter: CombatTeam;
  accepterPlayerIds: Array<Pilot>;
  accepterWinScores: Array<Scalars['Int']>;
  adminNote?: Maybe<Scalars['String']>;
  challenger: CombatTeam;
  challengerPlayerIds: Array<Pilot>;
  challengerWinScores: Array<Scalars['Int']>;
  chatroom?: Maybe<Chatroom>;
  combatQueues?: Maybe<Array<CombatQueue>>;
  completeTime?: Maybe<Scalars['ISO8601DateTime']>;
  createdAt: Scalars['ISO8601DateTime'];
  duration?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  isChallenger: Scalars['Boolean'];
  isWinner?: Maybe<Scalars['Boolean']>;
  loseTeam?: Maybe<Team>;
  matchResult?: Maybe<MatchResult>;
  matchResults?: Maybe<Array<MatchResult>>;
  rivalTeam: Team;
  scoreUpdate?: Maybe<Scalars['Int']>;
  screenshotBattleId?: Maybe<Scalars['String']>;
  screenshotDateTime?: Maybe<Scalars['ISO8601DateTime']>;
  screenshotRemark?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['ISO8601DateTime']>;
  state: CombatRoomState;
  updatedScores?: Maybe<Array<Scalars['Int']>>;
  winTeam?: Maybe<Team>;
};

export enum CombatRoomState {
  AccepterCompleted = 'accepter_completed',
  AccepterIdled = 'accepter_idled',
  AccepterReadied = 'accepter_readied',
  ChallengerCompleted = 'challenger_completed',
  ChallengerIdled = 'challenger_idled',
  ChallengerReadied = 'challenger_readied',
  Completed = 'completed',
  ConfirmCompleted = 'confirm_completed',
  ConfirmStarted = 'confirm_started',
  Flagged = 'flagged',
  Idled = 'idled',
  Matched = 'matched',
  Reviewed = 'reviewed',
  Started = 'started',
  Unscanned = 'unscanned'
}

/** Combat Team */
export type CombatTeam = {
  __typename?: 'CombatTeam';
  acceptedCombats: Array<CombatRoom>;
  allCombatRooms: Array<CombatRoom>;
  challengedCombats: Array<CombatRoom>;
  combatRoomAdminLogs: Array<AdminLog>;
  combatRooms: Array<CombatRoom>;
  createdAt: Scalars['ISO8601DateTime'];
  currentPosition?: Maybe<Scalars['Int']>;
  currentQueueId?: Maybe<Scalars['ID']>;
  endWeek: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  isOnline: Scalars['Boolean'];
  leaderboard: Leaderboard;
  leaderboardId?: Maybe<Scalars['String']>;
  leaderboards: Array<Leaderboard>;
  matchedQueue?: Maybe<CombatQueue>;
  players: Array<User>;
  poolPrize?: Maybe<PoolPrize>;
  position?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  score: Scalars['Int'];
  suspended?: Maybe<Scalars['Boolean']>;
  team: Team;
  teamId?: Maybe<Scalars['String']>;
  totalMatches: Scalars['Int'];
  updatedAt: Scalars['ISO8601DateTime'];
};


/** Combat Team */
export type CombatTeamAllCombatRoomsArgs = {
  leaderboardId?: Maybe<Scalars['ID']>;
};

export type CompleteBattleInput = {
  battleId: Scalars['ID'];
  id: Scalars['ID'];
  winTeamId: Scalars['ID'];
};

/** Autogenerated return type of CompleteBattle */
export type CompleteBattlePayload = {
  __typename?: 'CompleteBattlePayload';
  battle?: Maybe<Battle>;
  errors?: Maybe<Array<Error>>;
};

export type CompleteTournamentRoomInput = {
  id: Scalars['ID'];
  winTeamId: Scalars['ID'];
};

/** Autogenerated return type of CompleteTournamentRoom */
export type CompleteTournamentRoomPayload = {
  __typename?: 'CompleteTournamentRoomPayload';
  errors?: Maybe<Array<Error>>;
  tournamentRoom?: Maybe<TournamentRoom>;
};

export enum CountryCode {
  Id = 'ID',
  My = 'MY',
  Ph = 'PH',
  Sg = 'SG'
}

export type CreatePoolPrizeInput = {
  amount: Scalars['Int'];
  name: Scalars['String'];
  tournamentId: Scalars['ID'];
};

/** Autogenerated return type of CreatePoolPrize */
export type CreatePoolPrizePayload = {
  __typename?: 'CreatePoolPrizePayload';
  errors?: Maybe<Array<Error>>;
  poolPrize?: Maybe<PoolPrize>;
};

export type CreateTournamentEventInput = {
  endDate: Scalars['ISO8601DateTime'];
  endTime?: Maybe<Scalars['String']>;
  eventType: Event;
  name: Scalars['String'];
  position?: Maybe<Scalars['Int']>;
  startDate: Scalars['ISO8601DateTime'];
  startTime?: Maybe<Scalars['String']>;
  tournamentId: Scalars['ID'];
};

/** Autogenerated return type of CreateTournamentEvent */
export type CreateTournamentEventPayload = {
  __typename?: 'CreateTournamentEventPayload';
  errors?: Maybe<Array<Error>>;
  tournament?: Maybe<Tournament>;
  tournamentEvent?: Maybe<TournamentEvent>;
};

export type CreateTournamentInput = {
  apiKey?: Maybe<Scalars['String']>;
  backgroundImage: Scalars['File'];
  clanId: Scalars['ID'];
  countryCode: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['Boolean']>;
  gameId: Scalars['ID'];
  introText?: Maybe<Scalars['String']>;
  invitationCode?: Maybe<Scalars['String']>;
  logo: Scalars['File'];
  mode: TournamentMode;
  name: Scalars['String'];
  state?: Maybe<TournamentState>;
};

/** Autogenerated return type of CreateTournament */
export type CreateTournamentPayload = {
  __typename?: 'CreateTournamentPayload';
  errors?: Maybe<Array<Error>>;
  tournament?: Maybe<Tournament>;
};

export type CreateTournamentRoomInput = {
  battleType?: Maybe<TournamentRoomBattle>;
  checkIn?: Maybe<Scalars['ISO8601DateTime']>;
  roomName?: Maybe<Scalars['String']>;
  state?: Maybe<TournamentRoomState>;
  tournamentEventId: Scalars['ID'];
  tournamentTeamIds?: Maybe<Array<Scalars['ID']>>;
};

/** Autogenerated return type of CreateTournamentRoom */
export type CreateTournamentRoomPayload = {
  __typename?: 'CreateTournamentRoomPayload';
  errors?: Maybe<Array<Error>>;
  tournamentEvent?: Maybe<TournamentEvent>;
  tournamentRoom?: Maybe<TournamentRoom>;
};

/** Credit Transaction */
export type CreditTransaction = {
  __typename?: 'CreditTransaction';
  amount: Scalars['Float'];
  createdAt: Scalars['ISO8601DateTime'];
  creditWallet: CreditWallet;
  currency: Scalars['String'];
  id: Scalars['ID'];
  isCredit: Scalars['Boolean'];
  isDebit: Scalars['Boolean'];
  order?: Maybe<Order>;
  spinnable: Scalars['Boolean'];
  state: Scalars['String'];
  targetId?: Maybe<Scalars['ID']>;
  targetType?: Maybe<Scalars['String']>;
  teamWallet: TeamWallet;
  token?: Maybe<Scalars['String']>;
  transactionCategory: Scalars['Boolean'];
  transactionType: Transaction;
  userPrizes?: Maybe<Array<UserPrize>>;
};

/** Credit Transaction include counter */
export type CreditTransactionsCount = {
  __typename?: 'CreditTransactionsCount';
  count: Scalars['Int'];
  data: Array<CreditTransaction>;
};

/** Credit Wallet */
export type CreditWallet = {
  __typename?: 'CreditWallet';
  amount: Scalars['Float'];
  credits: Array<CreditTransaction>;
  creditTransactions: Array<CreditTransaction>;
  currency?: Maybe<Scalars['String']>;
  debits: Array<CreditTransaction>;
  id: Scalars['ID'];
  payouts: Array<Payout>;
  transactionHistory: Array<CreditTransaction>;
  user: User;
};

export enum Day {
  Anytime = 'anytime',
  Weekdays = 'weekdays',
  WeekendsHolidays = 'weekends_holidays'
}

/** Error */
export type Error = {
  __typename?: 'Error';
  detail: Scalars['String'];
  path?: Maybe<Scalars['String']>;
};

export enum Event {
  Final = 'final',
  PooledRoundRobin = 'pooled_round_robin',
  RandomPairings = 'random_pairings',
  Registration = 'registration',
  RoundRobin = 'round_robin',
  SemiFinal = 'semi_final',
  SingleElimination = 'single_elimination'
}


/** Game */
export type Game = {
  __typename?: 'Game';
  active?: Maybe<Scalars['Boolean']>;
  combat?: Maybe<Scalars['Boolean']>;
  developerName: Scalars['String'];
  gameAccount?: Maybe<GameAccount>;
  gameAccountsCount?: Maybe<Scalars['Int']>;
  gameChatroomId: Scalars['ID'];
  gameRanks: Array<GameRank>;
  genre?: Maybe<GameGenre>;
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  interests?: Maybe<Array<Interest>>;
  logoUrl?: Maybe<Scalars['String']>;
  mascotUrl?: Maybe<Scalars['String']>;
  matchmaking?: Maybe<Scalars['Boolean']>;
  minRank?: Maybe<GameRank>;
  name: Scalars['String'];
  ordering?: Maybe<Scalars['Int']>;
  partneringPenaltyRate?: Maybe<Scalars['Int']>;
  partneringRate?: Maybe<Scalars['Int']>;
  perspectives: Array<ProficientRole>;
  platform?: Maybe<GamePlatform>;
  proficientHeros: Array<ProficientHero>;
  proficientLanes?: Maybe<Array<ProficientRole>>;
  proficientRoles: Array<ProficientRole>;
  queues: Array<ProficientRole>;
  realTime?: Maybe<Scalars['Boolean']>;
  schedule?: Maybe<Scalars['Boolean']>;
  screenshotSampleUrl?: Maybe<Array<Scalars['String']>>;
  scrim?: Maybe<Scalars['Boolean']>;
  shortname?: Maybe<Scalars['String']>;
  teamsCount?: Maybe<Scalars['Int']>;
  testPassingScore?: Maybe<Scalars['Int']>;
  themeColor: Scalars['String'];
};


/** Game */
export type GameGameAccountArgs = {
  userId: Scalars['ID'];
};

/** Game Account */
export type GameAccount = {
  __typename?: 'GameAccount';
  accountId: Scalars['String'];
  approve: Scalars['Boolean'];
  club?: Maybe<Club>;
  codeState: CodeState;
  createdAt: Scalars['ISO8601DateTime'];
  currentLevel?: Maybe<PilotLevel>;
  currentReport?: Maybe<MonthlyReport>;
  game: Game;
  gameRank: GameRank;
  id: Scalars['ID'];
  inGameName?: Maybe<Scalars['String']>;
  kd?: Maybe<Scalars['Float']>;
  monthlyReports: Array<MonthlyReport>;
  nextLevel?: Maybe<PilotLevel>;
  onlineSchedules: Array<OnlineSchedule>;
  pairableGameRanks: Array<GameRank>;
  pilot: Pilot;
  pilotTier?: Maybe<PilotTier>;
  proficientHeros: Array<ProficientHero>;
  proficientLanes?: Maybe<Array<ProficientRole>>;
  proficientRoles: Array<ProficientRole>;
  rankPoint?: Maybe<Scalars['Int']>;
  rankStar?: Maybe<Scalars['Int']>;
  rankTier?: Maybe<Scalars['Int']>;
  rejectReason?: Maybe<Scalars['String']>;
  remindAt?: Maybe<Scalars['ISO8601DateTime']>;
  remindCount: Scalars['Int'];
  screenshotState: ScreenshotState;
  screenshotUrl?: Maybe<Array<Scalars['String']>>;
  serverId?: Maybe<Scalars['String']>;
  suspended: Scalars['Boolean'];
  testRetryCount: Scalars['Int'];
  testScore?: Maybe<Scalars['Float']>;
  testState: TestState;
  typeformUrl?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  verificationCode: Scalars['String'];
  winRate?: Maybe<Scalars['Float']>;
};

/** Game Bundle */
export type GameBundle = {
  __typename?: 'GameBundle';
  adminNote?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  bookingId: Scalars['String'];
  chatroom?: Maybe<Chatroom>;
  commission: Scalars['Float'];
  communicationRating?: Maybe<Scalars['Int']>;
  completionScreenshotUrl?: Maybe<Scalars['String']>;
  completionTime?: Maybe<Scalars['ISO8601DateTime']>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  currency: Scalars['String'];
  currentGameRank: GameRank;
  currentRankPoint?: Maybe<Scalars['Int']>;
  currentRankStar?: Maybe<Scalars['Int']>;
  currentRankTier?: Maybe<Scalars['Int']>;
  estimatedDurationInHour?: Maybe<Scalars['Float']>;
  game: Game;
  id: Scalars['ID'];
  openMic?: Maybe<Scalars['Boolean']>;
  originalAmount: Scalars['Float'];
  perspectives?: Maybe<Array<ProficientRole>>;
  pilot?: Maybe<SimpleUser>;
  pilotGameAccount?: Maybe<SimpleGameAccount>;
  pilotReview?: Maybe<Scalars['String']>;
  proficientHeros: Array<ProficientHero>;
  proficientLanes: Array<ProficientRole>;
  proficientRoles?: Maybe<Array<ProficientRole>>;
  promoCode?: Maybe<Scalars['String']>;
  queues?: Maybe<Array<ProficientRole>>;
  rating?: Maybe<Scalars['Int']>;
  ratingRemark?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  reporter?: Maybe<SimpleUser>;
  reportReason?: Maybe<Scalars['String']>;
  skillRating?: Maybe<Scalars['Int']>;
  startTime?: Maybe<Scalars['ISO8601DateTime']>;
  state: GameBundleState;
  targetGameRank: GameRank;
  targetRankPoint?: Maybe<Scalars['Int']>;
  targetRankStar?: Maybe<Scalars['Int']>;
  targetRankTier?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['ISO8601DateTime'];
  user: SimpleUser;
};

export enum GameBundleState {
  Available = 'available',
  Completed = 'completed',
  ConfirmStarted = 'confirm_started',
  ConfirmedCompletion = 'confirmed_completion',
  Expired = 'expired',
  GamerStarted = 'gamer_started',
  Grabbed = 'grabbed',
  Rated = 'rated',
  Refunded = 'refunded',
  Reported = 'reported',
  Reviewed = 'reviewed',
  Started = 'started'
}

export enum GameGenre {
  Action = 'action',
  Adventure = 'adventure',
  BattleRoyale = 'battle_royale',
  Card = 'card',
  Casual = 'casual',
  Fighting = 'fighting',
  Fps = 'fps',
  Mmorpg = 'mmorpg',
  Moba = 'moba',
  Puzzle = 'puzzle',
  Racing = 'racing',
  Rpg = 'rpg',
  Sandbox = 'sandbox',
  Shooter = 'shooter',
  Simulation = 'simulation',
  Sports = 'sports',
  Strategy = 'strategy',
  Survival = 'survival',
  TowerDefense = 'tower_defense'
}

export enum GameMode {
  Casual = 'casual',
  Other = 'other',
  Ranked = 'ranked'
}

export enum GamePlatform {
  Console = 'console',
  CrossPlatform = 'cross_platform',
  Desktop = 'desktop',
  Mobile = 'mobile'
}

/** Game Rank */
export type GameRank = {
  __typename?: 'GameRank';
  game: Game;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  ordering: Scalars['Int'];
  rankPointMaxCount?: Maybe<Array<Scalars['Int']>>;
  rankStarMaxCount?: Maybe<Scalars['Int']>;
  rankStarMinCount?: Maybe<Scalars['Int']>;
  rankTierMaxCount?: Maybe<Scalars['Int']>;
};

export enum Gender {
  Female = 'female',
  Male = 'male'
}

/** Indicators */
export type Indicator = {
  __typename?: 'Indicator';
  joinRequests: Scalars['Boolean'];
  messages: Scalars['Boolean'];
  teamInvitations: Scalars['Boolean'];
  teamMessages: Scalars['Boolean'];
};

/** Interest */
export type Interest = {
  __typename?: 'Interest';
  active: Scalars['Boolean'];
  iconOffUrl?: Maybe<Scalars['String']>;
  iconOnUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};


/** Join Request */
export type JoinRequest = {
  __typename?: 'JoinRequest';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  proficientRoles: Array<ProficientRole>;
  state: JoinRequestState;
  team: Team;
  user: User;
};

export enum JoinRequestState {
  Accepted = 'accepted',
  Cancelled = 'cancelled',
  Expired = 'expired',
  Pending = 'pending',
  Rejected = 'rejected'
}


export type Judge = {
  __typename?: 'Judge';
  apiKey?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  ipAddress?: Maybe<Scalars['String']>;
  judgeId: Scalars['String'];
  role: Scalars['String'];
  tournaments?: Maybe<Array<Tournament>>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type JudgeAuthorizationInput = {
  ipAddress: Scalars['String'];
  judgeId: Scalars['String'];
  password: Scalars['String'];
};

/** Autogenerated return type of JudgeAuthorization */
export type JudgeAuthorizationPayload = {
  __typename?: 'JudgeAuthorizationPayload';
  errors?: Maybe<Array<Error>>;
  judge?: Maybe<Judge>;
  jwt?: Maybe<Scalars['String']>;
};

export enum Language {
  Bm = 'bm',
  En = 'en',
  Id = 'id',
  Ms = 'ms',
  Zh = 'zh'
}

/** Leaderboard */
export type Leaderboard = {
  __typename?: 'Leaderboard';
  beginningWeek: Scalars['ISO8601DateTime'];
  combatTeams: Array<CombatTeam>;
  createdAt: Scalars['ISO8601DateTime'];
  currency: Scalars['String'];
  endWeek: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  month: Scalars['String'];
  numberOfWeek: Scalars['Int'];
  poolPrizes?: Maybe<Array<PoolPrize>>;
  totalPrize: Scalars['Float'];
  updatedAt: Scalars['ISO8601DateTime'];
  year: Scalars['String'];
};


/** Leaderboard */
export type LeaderboardCombatTeamsArgs = {
  page: Scalars['Int'];
};

export type MatchMaking = {
  __typename?: 'MatchMaking';
  backgroundImageUrl?: Maybe<Scalars['String']>;
  bookingAccounts: BookingCount;
  bookings?: Maybe<Array<Booking>>;
  createdAt: Scalars['ISO8601DateTime'];
  currency?: Maybe<Scalars['String']>;
  currentBooking?: Maybe<Booking>;
  endTime: Scalars['ISO8601DateTime'];
  game: Game;
  gameMode: GameMode;
  id: Scalars['ID'];
  inQueue: Scalars['Boolean'];
  isCreator: Scalars['Boolean'];
  matchCount?: Maybe<Scalars['Int']>;
  maximumRank?: Maybe<GameRank>;
  maximumRankId?: Maybe<Scalars['ID']>;
  minimumRank?: Maybe<GameRank>;
  minimumRankId?: Maybe<Scalars['ID']>;
  post: Post;
  price: Scalars['Int'];
  prosOnly?: Maybe<Scalars['Boolean']>;
  queueCount: Scalars['Int'];
  queueLimit?: Maybe<Scalars['Int']>;
  startTime: Scalars['ISO8601DateTime'];
  state: MatchMakingState;
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
};


export type MatchMakingBookingAccountsArgs = {
  page?: Maybe<Scalars['Int']>;
};

export enum MatchMakingState {
  Available = 'available',
  Inactived = 'inactived'
}

export type MatchResult = {
  __typename?: 'MatchResult';
  combatTeam?: Maybe<CombatTeam>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  loseTeam?: Maybe<Team>;
  resultScreenshots?: Maybe<Array<Scalars['String']>>;
  screenshotRemark?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  winTeam?: Maybe<Team>;
};

/** Merchandise */
export type Merchandise = {
  __typename?: 'Merchandise';
  active: Scalars['Boolean'];
  category: Category;
  club?: Maybe<Club>;
  createdAt: Scalars['ISO8601DateTime'];
  currency?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  images?: Maybe<Array<Scalars['String']>>;
  merchandiseVariants: Array<MerchandiseVariant>;
  name: Scalars['String'];
  position: Scalars['Int'];
  post: Post;
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
  updatedAt: Scalars['ISO8601DateTime'];
  user?: Maybe<User>;
};

/** Merchandise Variant */
export type MerchandiseVariant = {
  __typename?: 'MerchandiseVariant';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  merchandise: Merchandise;
  name: Scalars['String'];
  orders: Array<Order>;
  position: Scalars['Int'];
  price: Scalars['Float'];
  sku: Scalars['String'];
  stock: Scalars['Int'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Chatroom Message */
export type Message = {
  __typename?: 'Message';
  _id: Scalars['ID'];
  chatroomId: Scalars['ID'];
  clan?: Maybe<Clan>;
  clanId?: Maybe<Scalars['ID']>;
  contact?: Maybe<User>;
  contactId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['ISO8601DateTime'];
  forwardId?: Maybe<Scalars['ID']>;
  forwardMessage?: Maybe<Message>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  joinRequest?: Maybe<JoinRequest>;
  joinRequestId?: Maybe<Scalars['ID']>;
  post?: Maybe<Post>;
  postId?: Maybe<Scalars['ID']>;
  read: Scalars['Boolean'];
  replyId?: Maybe<Scalars['ID']>;
  replyMessage?: Maybe<Message>;
  scrim?: Maybe<Scrim>;
  scrimId?: Maybe<Scalars['ID']>;
  system: Scalars['Boolean'];
  team?: Maybe<Team>;
  teamId?: Maybe<Scalars['ID']>;
  teamInvitation?: Maybe<TeamInvitation>;
  teamInvitationId?: Maybe<Scalars['ID']>;
  text?: Maybe<Scalars['String']>;
  user: User;
};

/** Monthly Report */
export type MonthlyReport = {
  __typename?: 'MonthlyReport';
  cancelCount: Scalars['Int'];
  createdAt: Scalars['ISO8601DateTime'];
  gameAccount: GameAccount;
  id: Scalars['ID'];
  matchCount?: Maybe<Scalars['Int']>;
  month: Scalars['String'];
  nextLevel?: Maybe<PilotLevel>;
  pilotLevel: PilotLevel;
  pilotRewards: Array<PilotReward>;
  rating: Scalars['Float'];
  realtimeHours: Scalars['Float'];
  respondTime?: Maybe<Scalars['Float']>;
  scheduleCount: Scalars['Int'];
  year: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Judge Assign Tournament team */
  assignTeam?: Maybe<AssignTeamPayload>;
  /** Judge Cancel Tournament Room */
  cancelTournamentRoom?: Maybe<CancelTournamentRoomPayload>;
  /** Judge Complete Battle */
  completeBattle?: Maybe<CompleteBattlePayload>;
  /** Judge Disqualify Team */
  completeTournamentRoom?: Maybe<CompleteTournamentRoomPayload>;
  /** Judge create pool prize */
  createPoolPrize?: Maybe<CreatePoolPrizePayload>;
  /** Judge create tournament */
  createTournament?: Maybe<CreateTournamentPayload>;
  /** Judge Create Tournament Event */
  createTournamentEvent?: Maybe<CreateTournamentEventPayload>;
  /** Judge Create Tournament Room */
  createTournamentRoom?: Maybe<CreateTournamentRoomPayload>;
  /** Authorizations */
  judgeAuthorization?: Maybe<JudgeAuthorizationPayload>;
  /** Judge Cancel Tournament Room */
  randomTournamentRoom?: Maybe<RandomTournamentRoomPayload>;
  /** Judge remove pool prize */
  removePoolPrize?: Maybe<RemovePoolPrizePayload>;
  /** Judge Remove Tournament Event */
  removeTournamentEvent?: Maybe<RemoveTournamentEventPayload>;
  /** Judge Review tournament team */
  reviewTournamentTeam?: Maybe<ReviewTournamentTeamPayload>;
  /** Judge Start Tournament Room */
  startTournamentRoom?: Maybe<StartTournamentRoomPayload>;
  /** Judge Tournament Room */
  submitTournamentRoomName?: Maybe<SubmitTournamentRoomNamePayload>;
  /** Judge update pool prize */
  updatePoolPrize?: Maybe<UpdatePoolPrizePayload>;
  /** Judge update tournament */
  updateTournament?: Maybe<UpdateTournamentPayload>;
  /** Judge Update Tournament Event */
  updateTournamentEvent?: Maybe<UpdateTournamentEventPayload>;
  /** Judge Update Tournament Room */
  updateTournamentRoom?: Maybe<UpdateTournamentRoomPayload>;
};


export type MutationAssignTeamArgs = {
  input: AssignTeamInput;
};


export type MutationCancelTournamentRoomArgs = {
  input: CancelTournamentRoomInput;
};


export type MutationCompleteBattleArgs = {
  input: CompleteBattleInput;
};


export type MutationCompleteTournamentRoomArgs = {
  input: CompleteTournamentRoomInput;
};


export type MutationCreatePoolPrizeArgs = {
  input: CreatePoolPrizeInput;
};


export type MutationCreateTournamentArgs = {
  input: CreateTournamentInput;
};


export type MutationCreateTournamentEventArgs = {
  input: CreateTournamentEventInput;
};


export type MutationCreateTournamentRoomArgs = {
  input: CreateTournamentRoomInput;
};


export type MutationJudgeAuthorizationArgs = {
  input: JudgeAuthorizationInput;
};


export type MutationRandomTournamentRoomArgs = {
  input: RandomTournamentRoomInput;
};


export type MutationRemovePoolPrizeArgs = {
  input: RemovePoolPrizeInput;
};


export type MutationRemoveTournamentEventArgs = {
  input: RemoveTournamentEventInput;
};


export type MutationReviewTournamentTeamArgs = {
  input: ReviewTournamentTeamInput;
};


export type MutationStartTournamentRoomArgs = {
  input: StartTournamentRoomInput;
};


export type MutationSubmitTournamentRoomNameArgs = {
  input: SubmitTournamentRoomNameInput;
};


export type MutationUpdatePoolPrizeArgs = {
  input: UpdatePoolPrizeInput;
};


export type MutationUpdateTournamentArgs = {
  input: UpdateTournamentInput;
};


export type MutationUpdateTournamentEventArgs = {
  input: UpdateTournamentEventInput;
};


export type MutationUpdateTournamentRoomArgs = {
  input: UpdateTournamentRoomInput;
};

/** Notifications */
export type Notification = {
  __typename?: 'Notification';
  createdAt: Scalars['ISO8601DateTime'];
  description: Scalars['String'];
  game?: Maybe<Game>;
  id: Scalars['ID'];
  read: Scalars['Boolean'];
  targetId: Scalars['ID'];
  targetType?: Maybe<NotificationTarget>;
};

export enum NotificationTarget {
  Announcement = 'Announcement',
  CombatRoom = 'CombatRoom',
  CreditTransaction = 'CreditTransaction',
  GameBundle = 'GameBundle',
  Message = 'Message',
  PartneringRequest = 'PartneringRequest',
  Scrim = 'Scrim',
  User = 'User'
}

/** Og Data */
export type OgData = {
  __typename?: 'OgData';
  image: Scalars['String'];
  title: Scalars['String'];
};

/** Online Schedule */
export type OnlineSchedule = {
  __typename?: 'OnlineSchedule';
  dayType: Day;
  hours?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Order */
export type Order = {
  __typename?: 'Order';
  amount: Scalars['Float'];
  channel?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  creditTransaction?: Maybe<CreditTransaction>;
  currency?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  merchandiseVariant?: Maybe<MerchandiseVariant>;
  orderNumber?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  shippingAddress?: Maybe<ShippingAddress>;
  status: OrderStatus;
  trackingNumber?: Maybe<Scalars['String']>;
  trackingUrl?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export enum OrderStatus {
  Cancelled = 'cancelled',
  Completed = 'completed',
  Failed = 'failed',
  Paid = 'paid',
  Pending = 'pending',
  Refund = 'refund',
  Shipping = 'shipping',
  Succeeded = 'succeeded'
}

/** Partnering Request */
export type PartneringRequest = {
  __typename?: 'PartneringRequest';
  adminNote?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  cancellable?: Maybe<Scalars['Boolean']>;
  chatroom?: Maybe<Chatroom>;
  commission: Scalars['Float'];
  communicationRating?: Maybe<Scalars['Int']>;
  completeTime?: Maybe<Scalars['ISO8601DateTime']>;
  completionScreenshotUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  currency: Scalars['String'];
  durationHour?: Maybe<Scalars['Float']>;
  game: Game;
  gameRank: GameRank;
  id: Scalars['ID'];
  inGamePilotsGameAccounts?: Maybe<Array<SimpleGameAccount>>;
  matchCount?: Maybe<Scalars['Int']>;
  matchedServices?: Maybe<Array<PilotService>>;
  openMic?: Maybe<Scalars['Boolean']>;
  originalAmount: Scalars['Float'];
  partnerRateType: PartnerRate;
  penaltyAmount: Scalars['Float'];
  pendingServices?: Maybe<Array<PilotService>>;
  perspectives?: Maybe<Array<ProficientRole>>;
  pilot?: Maybe<SimpleUser>;
  pilotCount: Scalars['Int'];
  pilotGameAccount?: Maybe<SimpleGameAccount>;
  pilotNicknames?: Maybe<Scalars['String']>;
  pilotPrice: Scalars['Float'];
  pilotReview?: Maybe<Scalars['String']>;
  pilots?: Maybe<Array<SimpleUser>>;
  pilotServices?: Maybe<Array<PilotService>>;
  proficientLanes: Array<ProficientRole>;
  proficientRoles?: Maybe<Array<ProficientRole>>;
  promoApplied: Scalars['Boolean'];
  promoCode?: Maybe<Scalars['String']>;
  queues?: Maybe<Array<ProficientRole>>;
  rankPoint?: Maybe<Scalars['Int']>;
  rankStar?: Maybe<Scalars['Int']>;
  rankTier?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Int']>;
  ratingRemark?: Maybe<Scalars['String']>;
  readiedServices?: Maybe<Array<PilotService>>;
  reporter?: Maybe<SimpleUser>;
  reportReason?: Maybe<Scalars['String']>;
  skillRating?: Maybe<Scalars['Int']>;
  startTime?: Maybe<Scalars['ISO8601DateTime']>;
  state: PartneringRequestState;
  totalAmount: Scalars['Float'];
  totalDurationInMinutes?: Maybe<Scalars['Float']>;
  totalOriginalAmount: Scalars['Float'];
  updatedAt: Scalars['ISO8601DateTime'];
  user: SimpleUser;
  userGameAccount?: Maybe<SimpleGameAccount>;
};

export enum PartneringRequestState {
  AllStarted = 'all_started',
  Available = 'available',
  Cancelled = 'cancelled',
  Completed = 'completed',
  ConfirmStarted = 'confirm_started',
  GamerStarted = 'gamer_started',
  Idled = 'idled',
  Ignored = 'ignored',
  Matched = 'matched',
  PaymentOnHold = 'payment_on_hold',
  Rated = 'rated',
  Refunded = 'refunded',
  Reported = 'reported',
  Reviewed = 'reviewed',
  Started = 'started'
}

export enum PartnerRate {
  Normal = 'normal',
  Pro = 'pro'
}

/** Payout */
export type Payout = {
  __typename?: 'Payout';
  adminNote?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  approve: Scalars['Boolean'];
  bank: Scalars['String'];
  bankAccountNo: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  creditWallet: CreditWallet;
  currency: Scalars['String'];
  email: Scalars['String'];
  ic: Scalars['String'];
  id: Scalars['ID'];
  phone: Scalars['String'];
  pilot: Pilot;
  recipient?: Maybe<Scalars['String']>;
  rejectReason?: Maybe<Scalars['String']>;
  state?: Maybe<PayoutState>;
};

export enum PayoutState {
  Pending = 'pending',
  Processing = 'processing',
  Rejected = 'rejected',
  TransactionFailed = 'transaction_failed',
  TransactionSuccess = 'transaction_success'
}

/** Pilot */
export type Pilot = {
  __typename?: 'Pilot';
  acceptedPartneringRequests: Array<PartneringRequest>;
  adminNote?: Maybe<Scalars['String']>;
  aggregateRating: Scalars['Float'];
  allReports: Array<MonthlyReport>;
  availableGameBundles: Array<GameBundle>;
  avatarUrl?: Maybe<Scalars['String']>;
  averageRespondTime?: Maybe<Scalars['Int']>;
  bankInfo?: Maybe<BankInfo>;
  beKol: Scalars['Boolean'];
  bePro: Scalars['Boolean'];
  beStar: Scalars['Boolean'];
  birthday?: Maybe<Scalars['ISO8601DateTime']>;
  chatroom?: Maybe<Chatroom>;
  completeGameBundlesCount: Scalars['Int'];
  completePartneringRequestsCount: Scalars['Int'];
  createdAt: Scalars['ISO8601DateTime'];
  creditWallet: CreditWallet;
  discordId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  gameAccount?: Maybe<GameAccount>;
  gameAccounts: Array<GameAccount>;
  gameBundles: Array<GameBundle>;
  gender?: Maybe<Gender>;
  icName?: Maybe<Scalars['String']>;
  icNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  language: Language;
  lastSignInAt?: Maybe<Scalars['ISO8601DateTime']>;
  mlbbAccount?: Maybe<GameAccount>;
  nickname?: Maybe<Scalars['String']>;
  notifications: Array<Notification>;
  ongoingPartneringRequests: Array<PartneringRequest>;
  ongoingPilotServices: Array<PartneringRequest>;
  online: Scalars['Boolean'];
  partneringRequest?: Maybe<PartneringRequest>;
  partneringRequests: Array<PartneringRequest>;
  pendingPing?: Maybe<PartneringRequest>;
  pendingReward?: Maybe<PilotReward>;
  phoneNumber?: Maybe<Scalars['String']>;
  pilotAcceptedPartneringRequests: PilotPartneringRequestCount;
  pilotChatroom?: Maybe<Chatroom>;
  pilotCreditTransactions: PilotCreditTransactionCount;
  pilotGameBundles: PilotGameBundleCount;
  pilotRewards?: Maybe<Array<PilotReward>>;
  referralCode: Scalars['String'];
  rewardsCount: Scalars['Int'];
  signInCount: Scalars['Int'];
  streamer: Scalars['Boolean'];
  suspend: Scalars['Boolean'];
  suspendRequests?: Maybe<Array<SuspendRequest>>;
  team?: Maybe<Team>;
  teamRole?: Maybe<TeamRole>;
  totalRaterCount?: Maybe<Scalars['Int']>;
  trained: Scalars['Boolean'];
  updatedAt: Scalars['ISO8601DateTime'];
  userGameAccounts: Array<UserGameAccount>;
  userIdApple?: Maybe<Scalars['String']>;
  userIdGoogle?: Maybe<Scalars['String']>;
  userIdInstagram?: Maybe<Scalars['String']>;
  userIdTiktok?: Maybe<Scalars['String']>;
  verified: Scalars['Boolean'];
};


/** Pilot */
export type PilotAvailableGameBundlesArgs = {
  gameId?: Maybe<Scalars['ID']>;
};


/** Pilot */
export type PilotChatroomArgs = {
  id: Scalars['ID'];
};


/** Pilot */
export type PilotGameAccountArgs = {
  gameId: Scalars['ID'];
};


/** Pilot */
export type PilotGameBundlesArgs = {
  states?: Maybe<Array<GameBundleState>>;
};


/** Pilot */
export type PilotNotificationsArgs = {
  gameId?: Maybe<Scalars['ID']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


/** Pilot */
export type PilotPartneringRequestArgs = {
  id: Scalars['ID'];
};


/** Pilot */
export type PilotPilotAcceptedPartneringRequestsArgs = {
  page?: Maybe<Scalars['Int']>;
};


/** Pilot */
export type PilotPilotChatroomArgs = {
  id: Scalars['ID'];
};


/** Pilot */
export type PilotPilotCreditTransactionsArgs = {
  page?: Maybe<Scalars['Int']>;
};


/** Pilot */
export type PilotPilotGameBundlesArgs = {
  page?: Maybe<Scalars['Int']>;
};


/** Pilot */
export type PilotPilotRewardsArgs = {
  page?: Maybe<Scalars['Int']>;
};

/** Pilot Credit Transaction include counter */
export type PilotCreditTransactionCount = {
  __typename?: 'PilotCreditTransactionCount';
  count: Scalars['Int'];
  data: Array<CreditTransaction>;
};

/** Pilot Game bundles include counter */
export type PilotGameBundleCount = {
  __typename?: 'PilotGameBundleCount';
  count: Scalars['Int'];
  data: Array<GameBundle>;
};

/** Pilot Level */
export type PilotLevel = {
  __typename?: 'PilotLevel';
  commission: Scalars['Float'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  maxCancelCount: Scalars['Int'];
  maxIdleCount: Scalars['Int'];
  maxMicOffCount: Scalars['Int'];
  maxRespondTime: Scalars['Float'];
  minRating: Scalars['Float'];
  minRealtimeCount: Scalars['Int'];
  minRealtimeHour: Scalars['Float'];
  minScheduleCount: Scalars['Int'];
  name: Scalars['String'];
  position: Scalars['Int'];
  rewards?: Maybe<Array<Reward>>;
  rewardValue: Scalars['Int'];
};

/** Pilot Partnering Request include counter */
export type PilotPartneringRequestCount = {
  __typename?: 'PilotPartneringRequestCount';
  count: Scalars['Int'];
  data: Array<PartneringRequest>;
};

/** Pilot Reward */
export type PilotReward = {
  __typename?: 'PilotReward';
  address?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  fullName?: Maybe<Scalars['String']>;
  icNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inGameId?: Maybe<Scalars['String']>;
  inGameName?: Maybe<Scalars['String']>;
  monthlyReport: MonthlyReport;
  pilot: Pilot;
  pilotLevel?: Maybe<PilotLevel>;
  remark?: Maybe<Scalars['String']>;
  reward?: Maybe<Reward>;
  state: PilotRewardState;
};

export enum PilotRewardState {
  Cancelled = 'cancelled',
  Delivered = 'delivered',
  PendingInfo = 'pending_info',
  PendingReward = 'pending_reward',
  Processing = 'processing'
}

/** Pilot Service */
export type PilotService = {
  __typename?: 'PilotService';
  commission: Scalars['Float'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  partneringRequestId: Scalars['ID'];
  pilot: SimpleUser;
  reason?: Maybe<Scalars['String']>;
  state: PilotServiceState;
};

export enum PilotServiceState {
  Cancelled = 'cancelled',
  Completed = 'completed',
  Pending = 'pending',
  Readied = 'readied',
  Rejected = 'rejected'
}

/** Pilot Tier */
export type PilotTier = {
  __typename?: 'PilotTier';
  aggregateRating: Scalars['Float'];
  completeGameBundlesCount: Scalars['Int'];
  completePartneringRequestsCount: Scalars['Int'];
  game: Game;
  gameAccount?: Maybe<GameAccount>;
  gameAccounts: Array<GameAccount>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  pilots?: Maybe<Array<Pilot>>;
};


/** Pilot Tier */
export type PilotTierGameAccountArgs = {
  gameId: Scalars['ID'];
};

/** Pool Prize */
export type PoolPrize = {
  __typename?: 'PoolPrize';
  amount?: Maybe<Scalars['Float']>;
  combatTeam?: Maybe<CombatTeam>;
  createdAt: Scalars['ISO8601DateTime'];
  currency?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  leaderboard?: Maybe<Leaderboard>;
  name?: Maybe<Scalars['String']>;
  ordering: Scalars['Int'];
  tournament?: Maybe<Tournament>;
  tournamentTeam?: Maybe<TournamentTeam>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Post */
export type Post = {
  __typename?: 'Post';
  active?: Maybe<Scalars['Boolean']>;
  aspectRatio?: Maybe<Scalars['Float']>;
  assetId?: Maybe<Scalars['ID']>;
  author?: Maybe<User>;
  body?: Maybe<Scalars['String']>;
  commentsCount: Scalars['Int'];
  createdAt: Scalars['ISO8601DateTime'];
  favCount: Scalars['Int'];
  faved: Scalars['Boolean'];
  followed: Scalars['Boolean'];
  id: Scalars['ID'];
  imageRatio?: Maybe<Scalars['Float']>;
  isYoutube?: Maybe<Scalars['Boolean']>;
  likeCount: Scalars['Int'];
  liked?: Maybe<Scalars['Boolean']>;
  matchMaking?: Maybe<MatchMaking>;
  merchandises?: Maybe<Array<Merchandise>>;
  ogData?: Maybe<OgData>;
  playbackId?: Maybe<Scalars['ID']>;
  postComments?: Maybe<Array<PostComment>>;
  postImages?: Maybe<Array<Scalars['String']>>;
  postVideos?: Maybe<Array<Scalars['String']>>;
  proTip?: Maybe<ProTip>;
  reported: Scalars['Boolean'];
  targetId?: Maybe<Scalars['ID']>;
  targetType?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  topic?: Maybe<Topic>;
  uploadId?: Maybe<Scalars['ID']>;
  url?: Maybe<Scalars['String']>;
  views?: Maybe<Scalars['Int']>;
};

/** Post Comment */
export type PostComment = {
  __typename?: 'PostComment';
  body: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  post: Post;
  postId: Scalars['ID'];
  postTitle?: Maybe<Scalars['String']>;
  user: User;
};

/** Prize */
export type Prize = {
  __typename?: 'Prize';
  active: Scalars['Boolean'];
  createdAt: Scalars['ISO8601DateTime'];
  icon: Scalars['String'];
  id: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  position: Scalars['Int'];
  prizeName: Scalars['String'];
  prizeType?: Maybe<PrizeType>;
  value: Scalars['Float'];
};

export enum PrizeType {
  CustomJob = 'custom_job',
  EliteSkin = 'elite_skin',
  EpicSkin = 'epic_skin',
  FirstPrize = 'first_prize',
  GameCredit = 'game_credit',
  GameItem = 'game_item',
  GrandPrize = 'grand_prize',
  PromoCode = 'promo_code',
  SecondPrize = 'second_prize',
  ThirdPrize = 'third_prize',
  Tshirt = 'tshirt'
}

/** Proficient Hero */
export type ProficientHero = {
  __typename?: 'ProficientHero';
  game: Game;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  proficientRole: ProficientRole;
};

/** Proficient Role */
export type ProficientRole = {
  __typename?: 'ProficientRole';
  game: Game;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  kind?: Maybe<ProficientRoleKind>;
  name: Scalars['String'];
  proficientHeros?: Maybe<Array<ProficientHero>>;
  selectedImageUrl?: Maybe<Scalars['String']>;
};

export enum ProficientRoleKind {
  HeroRole = 'hero_role',
  Lane = 'lane',
  Perspective = 'perspective',
  Queue = 'queue'
}

/** Promo Code */
export type PromoCode = {
  __typename?: 'PromoCode';
  active: Scalars['Boolean'];
  amount: Scalars['Int'];
  availableFor: Scalars['String'];
  code: Scalars['String'];
  currency?: Maybe<Scalars['String']>;
  gameType: Scalars['String'];
  id: Scalars['ID'];
  minimumAmount: Scalars['Int'];
  prosOnly?: Maybe<Scalars['Boolean']>;
  redeemLimit?: Maybe<Scalars['Int']>;
  specifiedClub?: Maybe<Club>;
  specifiedTier?: Maybe<PilotTier>;
  userIds?: Maybe<Array<Scalars['String']>>;
  validUntil?: Maybe<Scalars['ISO8601DateTime']>;
  whitelistsOnly?: Maybe<Scalars['Boolean']>;
};

export type ProTip = {
  __typename?: 'ProTip';
  id: Scalars['ID'];
  image: Scalars['String'];
  prosOnly: Scalars['Boolean'];
  text: Scalars['String'];
  video: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  /** Get a Battle with ID */
  battle: Battle;
  /** Get all Battle with tournament room ID */
  battles: Array<Battle>;
  /** Get camp */
  camp: Camp;
  /** Get camp players */
  campPlayer: CampPlayer;
  /** Get all camp players */
  campPlayers: Array<CampPlayer>;
  /** Get a Battle with ID */
  camps: Array<Camp>;
  clans?: Maybe<Array<Clan>>;
  games?: Maybe<Array<Game>>;
  me?: Maybe<Judge>;
  /** Get a teams with Tournament event ID */
  teams: Array<Team>;
  /** Get a tournament with ID */
  tournament: Tournament;
  /** Get a tournament event with ID */
  tournamentEvent: TournamentEvent;
  /** Get all tournament events */
  tournamentEvents: Array<TournamentEvent>;
  /** Get a tournament team with ID */
  tournamentRoom: TournamentRoom;
  /** Get all tournament rooms */
  tournamentRooms: Array<TournamentRoom>;
  /** Get all tournaments */
  tournaments: Array<Tournament>;
  /** Get a tournament team with ID */
  tournamentTeam: TournamentTeam;
  tournamentTeamAccounts?: Maybe<TournamentTeamCount>;
  /** Get a tournament team with ID */
  tournamentTeamCsv?: Maybe<Scalars['String']>;
};


export type QueryBattleArgs = {
  id: Scalars['ID'];
};


export type QueryBattlesArgs = {
  state?: Maybe<BattleState>;
  tournamentRoomId: Scalars['ID'];
};


export type QueryCampArgs = {
  id: Scalars['ID'];
};


export type QueryCampPlayerArgs = {
  id: Scalars['ID'];
};


export type QueryCampPlayersArgs = {
  campId: Scalars['ID'];
};


export type QueryCampsArgs = {
  battleId: Scalars['ID'];
};


export type QueryTeamsArgs = {
  search?: Maybe<Scalars['String']>;
  tournamentEventId: Scalars['ID'];
  tournamentTeamState?: Maybe<Scalars['String']>;
};


export type QueryTournamentArgs = {
  id: Scalars['ID'];
};


export type QueryTournamentEventArgs = {
  id: Scalars['ID'];
};


export type QueryTournamentEventsArgs = {
  state?: Maybe<TournamentEventState>;
  tournamentId: Scalars['ID'];
};


export type QueryTournamentRoomArgs = {
  id: Scalars['ID'];
};


export type QueryTournamentRoomsArgs = {
  state?: Maybe<TournamentRoomState>;
  tournamentEventId: Scalars['ID'];
};


export type QueryTournamentsArgs = {
  clanId?: Maybe<Scalars['ID']>;
  gameId?: Maybe<Scalars['ID']>;
  judgeId?: Maybe<Scalars['ID']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  organizerId?: Maybe<Scalars['ID']>;
  states?: Maybe<Array<TournamentState>>;
};


export type QueryTournamentTeamArgs = {
  id: Scalars['ID'];
};


export type QueryTournamentTeamAccountsArgs = {
  page?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  tournamentId?: Maybe<Scalars['ID']>;
};


export type QueryTournamentTeamCsvArgs = {
  tournamentId: Scalars['ID'];
};

export type RandomTournamentRoomInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of RandomTournamentRoom */
export type RandomTournamentRoomPayload = {
  __typename?: 'RandomTournamentRoomPayload';
  errors?: Maybe<Array<Error>>;
  tournamentEvent?: Maybe<TournamentEvent>;
};

/** Referrer */
export type Referrer = {
  __typename?: 'Referrer';
  avatarUrl?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  nickname?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
};

export type RemovePoolPrizeInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of RemovePoolPrize */
export type RemovePoolPrizePayload = {
  __typename?: 'RemovePoolPrizePayload';
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean'];
};

export type RemoveTournamentEventInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of RemoveTournamentEvent */
export type RemoveTournamentEventPayload = {
  __typename?: 'RemoveTournamentEventPayload';
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean'];
  tournamentEvent?: Maybe<TournamentEvent>;
};

export type ReviewTournamentTeamInput = {
  id: Scalars['ID'];
  stateRequest: Scalars['String'];
};

/** Autogenerated return type of ReviewTournamentTeam */
export type ReviewTournamentTeamPayload = {
  __typename?: 'ReviewTournamentTeamPayload';
  errors?: Maybe<Array<Error>>;
  tournamentTeam?: Maybe<TournamentTeam>;
};

/** Reward */
export type Reward = {
  __typename?: 'Reward';
  id: Scalars['ID'];
  name: Scalars['String'];
  pilotLevel: PilotLevel;
  rewardType: RewardType;
};

export enum RewardType {
  Cash = 'cash',
  GameCredit = 'game_credit',
  GameItem = 'game_item',
  PromoCode = 'promo_code'
}

export enum ScreenshotState {
  PendingScreenshotResubmission = 'pending_screenshot_resubmission',
  ScreenshotApproved = 'screenshot_approved',
  ScreenshotSubmitted = 'screenshot_submitted',
  ScreenshotUnverified = 'screenshot_unverified'
}

export type Scrim = {
  __typename?: 'Scrim';
  chatroom?: Maybe<Chatroom>;
  completeTime?: Maybe<Scalars['ISO8601DateTime']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  guest?: Maybe<Team>;
  guestPlayers: Array<Pilot>;
  host: Team;
  hostPlayers: Array<Pilot>;
  id: Scalars['ID'];
  matchCount?: Maybe<Scalars['Int']>;
  matchResults: Array<MatchResult>;
  realtime: Scalars['Boolean'];
  roles: Array<Scalars['String']>;
  startTime?: Maybe<Scalars['ISO8601DateTime']>;
  state: ScrimState;
};

export enum ScrimState {
  Available = 'available',
  Cancelled = 'cancelled',
  Completed = 'completed',
  ConfirmCompleted = 'confirm_completed',
  ConfirmStarted = 'confirm_started',
  Grabbed = 'grabbed',
  Matched = 'matched',
  Reported = 'reported',
  Reviewed = 'reviewed',
  Started = 'started'
}

export type ShippingAddress = {
  __typename?: 'ShippingAddress';
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  addressName: Scalars['String'];
  country: Scalars['String'];
  defaultAddress: Scalars['Boolean'];
  id: Scalars['ID'];
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  orders: Array<Order>;
  postalCode: Scalars['String'];
  recipientName?: Maybe<Scalars['String']>;
  recipientPhoneNumber?: Maybe<Scalars['String']>;
  state: ShippingAddressState;
  town: Scalars['String'];
  user: User;
};

export enum ShippingAddressState {
  Johor = 'Johor',
  Kedah = 'Kedah',
  Kelantan = 'Kelantan',
  /** Kuala Lumpur */
  KualaLumpur = 'Kuala_Lumpur',
  Labuan = 'Labuan',
  Melaka = 'Melaka',
  /** Negeri Sembilan */
  NegeriSembilan = 'Negeri_Sembilan',
  Pahang = 'Pahang',
  Penang = 'Penang',
  Perak = 'Perak',
  Perlis = 'Perlis',
  Putrajaya = 'Putrajaya',
  Sabah = 'Sabah',
  Sarawak = 'Sarawak',
  Selangor = 'Selangor',
  Terengganu = 'Terengganu'
}

/** Simple Game Account */
export type SimpleGameAccount = {
  __typename?: 'SimpleGameAccount';
  accountId: Scalars['String'];
  club?: Maybe<Club>;
  gameRank: SimpleGameRank;
  id: Scalars['ID'];
  kd?: Maybe<Scalars['Float']>;
  nickname: Scalars['String'];
  proficientLanes?: Maybe<Array<ProficientRole>>;
  proficientRoles?: Maybe<Array<ProficientRole>>;
  rankPoint?: Maybe<Scalars['Int']>;
  rankStar?: Maybe<Scalars['Int']>;
  rankTier?: Maybe<Scalars['Int']>;
  winRate?: Maybe<Scalars['Float']>;
};

/** Simple Game Rank */
export type SimpleGameRank = {
  __typename?: 'SimpleGameRank';
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Simple User */
export type SimpleUser = {
  __typename?: 'SimpleUser';
  _id: Scalars['ID'];
  aggregateRating?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['String']>;
  avatarUrl?: Maybe<Scalars['String']>;
  averageRespondTime?: Maybe<Scalars['Int']>;
  birthday?: Maybe<Scalars['ISO8601DateTime']>;
  completeGameBundlesCount?: Maybe<Scalars['Int']>;
  completePartneringRequestsCount?: Maybe<Scalars['Int']>;
  discordId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  icName?: Maybe<Scalars['String']>;
  icNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  language: Language;
  name?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  suspend: Scalars['Boolean'];
  suspendGamerRequests?: Maybe<Array<SuspendGamerRequest>>;
  totalRaterCount?: Maybe<Scalars['Int']>;
  userPrizes?: Maybe<Array<UserPrize>>;
};

export type StartTournamentRoomInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of StartTournamentRoom */
export type StartTournamentRoomPayload = {
  __typename?: 'StartTournamentRoomPayload';
  errors?: Maybe<Array<Error>>;
  tournamentRoom?: Maybe<TournamentRoom>;
};

export type SubmitTournamentRoomNameInput = {
  id: Scalars['ID'];
  roomName: Scalars['String'];
};

/** Autogenerated return type of SubmitTournamentRoomName */
export type SubmitTournamentRoomNamePayload = {
  __typename?: 'SubmitTournamentRoomNamePayload';
  errors?: Maybe<Array<Error>>;
  tournamentRoom?: Maybe<TournamentRoom>;
};

export type SuspendGamerRequest = {
  __typename?: 'SuspendGamerRequest';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  reason: Scalars['String'];
  state: SuspendRequestState;
};

export type SuspendRequest = {
  __typename?: 'SuspendRequest';
  admin: Admin;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  reason: Scalars['String'];
  state: SuspendRequestState;
};

export enum SuspendRequestState {
  Approved = 'approved',
  Pending = 'pending',
  Rejected = 'rejected'
}

export type Team = {
  __typename?: 'Team';
  allChatrooms: Array<Chatroom>;
  allCombatRooms: TeamCombatRoomCount;
  allCombatRoomSize: Scalars['Int'];
  allScrims: Array<Scrim>;
  availableRealtimes: Array<Scrim>;
  availableSchedules: Array<Scrim>;
  bankInfo?: Maybe<BankInfo>;
  careerHighlights?: Maybe<Array<CareerHighlight>>;
  chatroomId: Scalars['ID'];
  club?: Maybe<Club>;
  clubId: Scalars['ID'];
  combatTeams?: Maybe<Array<CombatTeam>>;
  country: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  currentCombat?: Maybe<CombatTeam>;
  currentCombatId?: Maybe<Scalars['ID']>;
  currentCombatRoom?: Maybe<CombatRoom>;
  deleted: Scalars['Boolean'];
  founded?: Maybe<Scalars['ISO8601DateTime']>;
  game: Game;
  gameId: Scalars['ID'];
  guestScrims?: Maybe<Array<Scrim>>;
  hostScrims?: Maybe<Array<Scrim>>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isManager: Scalars['Boolean'];
  joinRequests: Array<JoinRequest>;
  lastCombatTeamScore: Scalars['Int'];
  lastMessage?: Maybe<Message>;
  location?: Maybe<Scalars['String']>;
  loseMatches?: Maybe<Array<MatchResult>>;
  manager: User;
  matchCount?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  onlineCount: Scalars['Int'];
  overview?: Maybe<Scalars['String']>;
  paginatedHistoryLeaderboard: Array<CombatRoom>;
  paginatedRealtimes: Array<Scrim>;
  paginatedSchedules: Array<Scrim>;
  pendingInvitationId?: Maybe<Scalars['ID']>;
  pendingRequestId?: Maybe<Scalars['ID']>;
  pilots: Array<Pilot>;
  reason?: Maybe<Scalars['String']>;
  recentCombats?: Maybe<Array<CombatRoom>>;
  registeredTournament: Scalars['Boolean'];
  scrim?: Maybe<Scrim>;
  scrimable: Scalars['Boolean'];
  state?: Maybe<Scalars['String']>;
  suspend?: Maybe<Scalars['Boolean']>;
  teamChatroom: Chatroom;
  teamCombatTeams?: Maybe<Array<CombatTeam>>;
  teamInvitations: Array<TeamInvitation>;
  teamMembers: Scalars['Int'];
  teamRolePlayers: Array<User>;
  teamRoles: Array<TeamRole>;
  teamWallet?: Maybe<TeamWallet>;
  themeColor?: Maybe<Scalars['String']>;
  themeColors?: Maybe<Array<Scalars['String']>>;
  tier?: Maybe<Scalars['String']>;
  tournamentTeam?: Maybe<TournamentTeam>;
  tournamentTeams?: Maybe<Array<TournamentTeam>>;
  town?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  users: Array<User>;
  usersCount: Scalars['Int'];
  verified: Scalars['Boolean'];
  winCount?: Maybe<Scalars['Int']>;
  winMatches?: Maybe<Array<MatchResult>>;
};


export type TeamAllCombatRoomsArgs = {
  page?: Maybe<Scalars['Int']>;
};


export type TeamPaginatedHistoryLeaderboardArgs = {
  leaderboardId?: Maybe<Scalars['ID']>;
};


export type TeamPaginatedRealtimesArgs = {
  page?: Maybe<Scalars['Int']>;
  states: Array<ScrimState>;
};


export type TeamPaginatedSchedulesArgs = {
  page?: Maybe<Scalars['Int']>;
  states: Array<ScrimState>;
};


export type TeamScrimArgs = {
  scrimId: Scalars['ID'];
};


export type TeamUsersArgs = {
  all?: Maybe<Scalars['Boolean']>;
  searchUser?: Maybe<Scalars['String']>;
};

/** Team Combat Rooms include counter */
export type TeamCombatRoomCount = {
  __typename?: 'TeamCombatRoomCount';
  count: Scalars['Int'];
  data: Array<CombatRoom>;
};

export type TeamInvitation = {
  __typename?: 'TeamInvitation';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  pilot?: Maybe<Pilot>;
  state: TeamInvitationState;
  team: Team;
  updatedAt: Scalars['ISO8601DateTime'];
  user?: Maybe<User>;
};

export enum TeamInvitationState {
  Accepted = 'accepted',
  Cancelled = 'cancelled',
  Pending = 'pending',
  Rejected = 'rejected'
}

/** TeamPayout */
export type TeamPayout = {
  __typename?: 'TeamPayout';
  adminNote?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  approve: Scalars['Boolean'];
  bank: Scalars['String'];
  bankAccountNo: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  currency?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  ic: Scalars['String'];
  id: Scalars['ID'];
  phone?: Maybe<Scalars['String']>;
  pilot: Pilot;
  recipient?: Maybe<Scalars['String']>;
  rejectReason?: Maybe<Scalars['String']>;
  state?: Maybe<TeamPayoutState>;
  teamWallet: TeamWallet;
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum TeamPayoutState {
  Pending = 'pending',
  Processing = 'processing',
  Rejected = 'rejected',
  TransactionFailed = 'transaction_failed',
  TransactionSuccess = 'transaction_success'
}

export type TeamRole = {
  __typename?: 'TeamRole';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  online: Scalars['Boolean'];
  pilot?: Maybe<Pilot>;
  proficientRole?: Maybe<ProficientRole>;
  team: Team;
  user?: Maybe<User>;
};

/** Team Wallet */
export type TeamWallet = {
  __typename?: 'TeamWallet';
  amount: Scalars['Float'];
  credits: Array<CreditTransaction>;
  creditTransactions: Array<CreditTransaction>;
  currency?: Maybe<Scalars['String']>;
  debits: Array<CreditTransaction>;
  id: Scalars['ID'];
  team: Team;
  teamCreditTransactions: CreditTransactionsCount;
  teamPayouts: Array<TeamPayout>;
  transactionHistory: Array<CreditTransaction>;
};


/** Team Wallet */
export type TeamWalletTeamCreditTransactionsArgs = {
  page?: Maybe<Scalars['Int']>;
};

export enum TestState {
  TestFailed = 'test_failed',
  TestNonRetryable = 'test_non_retryable',
  TestPassed = 'test_passed',
  TestPending = 'test_pending'
}

export type Topic = {
  __typename?: 'Topic';
  id: Scalars['ID'];
  image: Scalars['String'];
  prosOnly: Scalars['Boolean'];
  text: Scalars['String'];
  video: Scalars['String'];
};

export type Tournament = {
  __typename?: 'Tournament';
  apiKey?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['String']>;
  clan?: Maybe<Clan>;
  countryCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  featured?: Maybe<Scalars['Boolean']>;
  game: Game;
  id: Scalars['ID'];
  introText?: Maybe<Scalars['String']>;
  invitationCode?: Maybe<Scalars['String']>;
  isOrganizer: Scalars['Boolean'];
  judges?: Maybe<Array<Judge>>;
  logo?: Maybe<Scalars['String']>;
  mode: TournamentMode;
  name: Scalars['String'];
  organizer?: Maybe<User>;
  participantsCount?: Maybe<Scalars['Int']>;
  poolPrizes?: Maybe<Array<PoolPrize>>;
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
  state: TournamentState;
  tournamentEvents?: Maybe<Array<TournamentEvent>>;
  tournamentSettings?: Maybe<Array<TournamentSetting>>;
  tournamentTeams?: Maybe<Array<TournamentTeam>>;
  tournamentUsers?: Maybe<Array<TournamentUser>>;
  updatedAt: Scalars['ISO8601DateTime'];
  verified?: Maybe<Scalars['Boolean']>;
};

export type TournamentEvent = {
  __typename?: 'TournamentEvent';
  createdAt: Scalars['ISO8601DateTime'];
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  eventType?: Maybe<TournamentEventEvent>;
  id: Scalars['ID'];
  judge?: Maybe<User>;
  name: Scalars['String'];
  position: Scalars['Int'];
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
  state?: Maybe<Scalars['String']>;
  tournament: Tournament;
  tournamentRooms?: Maybe<Array<TournamentRoom>>;
  updatedAt: Scalars['ISO8601DateTime'];
  user?: Maybe<User>;
};

export enum TournamentEventEvent {
  Final = 'final',
  PooledRoundRobin = 'pooled_round_robin',
  RandomPairings = 'random_pairings',
  Registration = 'registration',
  RoundRobin = 'round_robin',
  SemiFinal = 'semi_final',
  SingleElimination = 'single_elimination'
}

export enum TournamentEventState {
  Cancelled = 'cancelled',
  Completed = 'completed',
  Pending = 'pending',
  Started = 'started'
}

export enum TournamentMode {
  Solo = 'solo',
  TeamMatch = 'team_match'
}

/** Tournament Room */
export type TournamentRoom = {
  __typename?: 'TournamentRoom';
  battles?: Maybe<Array<Battle>>;
  battleType: TournamentRoomBattle;
  chatroom?: Maybe<Chatroom>;
  checkIn?: Maybe<Scalars['ISO8601DateTime']>;
  checkInMembers?: Maybe<Array<TournamentTeam>>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  judge?: Maybe<Judge>;
  loseTeam?: Maybe<TournamentTeam>;
  roomName?: Maybe<Scalars['String']>;
  state: TournamentRoomState;
  tournamentEvent: TournamentEvent;
  tournamentTeams?: Maybe<Array<TournamentTeam>>;
  updatedAt: Scalars['ISO8601DateTime'];
  winTeam?: Maybe<TournamentTeam>;
  winTeamId?: Maybe<Scalars['Int']>;
};


/** Tournament Room */
export type TournamentRoomChatroomArgs = {
  id: Scalars['ID'];
};

export enum TournamentRoomBattle {
  B03 = 'B03',
  B05 = 'B05',
  B07 = 'B07',
  SingleElimination = 'single_elimination'
}

export enum TournamentRoomState {
  Cancelled = 'cancelled',
  Completed = 'completed',
  Expired = 'expired',
  Initiating = 'initiating',
  Started = 'started'
}

/** Tournament Settings */
export type TournamentSetting = {
  __typename?: 'TournamentSetting';
  name: Scalars['String'];
  tournament: Tournament;
  value: Scalars['String'];
};

export enum TournamentState {
  Active = 'active',
  Cancelled = 'cancelled',
  Completed = 'completed',
  Expired = 'expired',
  Started = 'started',
  Suspended = 'suspended'
}

export type TournamentTeam = {
  __typename?: 'TournamentTeam';
  camps?: Maybe<Array<Camp>>;
  captain: User;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  isManager: Scalars['Boolean'];
  players: Array<User>;
  state: TournamentTeamState;
  subPlayers: Array<User>;
  team: Team;
  teamName?: Maybe<Scalars['String']>;
  tournament: Tournament;
  tournamentRooms?: Maybe<Array<TournamentRoom>>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Tournament Teams include counter */
export type TournamentTeamCount = {
  __typename?: 'TournamentTeamCount';
  count: Scalars['Int'];
  data: Array<TournamentTeam>;
};

export enum TournamentTeamState {
  Cancelled = 'cancelled',
  Disqualified = 'disqualified',
  Pending = 'pending',
  Qualified = 'qualified'
}

export type TournamentUser = {
  __typename?: 'TournamentUser';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  state: TournamentUserState;
  tournament: Tournament;
  tournamentRooms?: Maybe<Array<TournamentRoom>>;
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
};

export enum TournamentUserState {
  Cancelled = 'cancelled',
  Disqualified = 'disqualified',
  Pending = 'pending',
  Qualified = 'qualified'
}

export enum Transaction {
  Bonus = 'bonus',
  CancelFee = 'cancel_fee',
  ContestBonus = 'contest_bonus',
  DepositDeduction = 'deposit_deduction',
  Earn = 'earn',
  Merchandise = 'merchandise',
  Payout = 'payout',
  Penalty = 'penalty',
  PoolPrize = 'pool_prize',
  RankReward = 'rank_reward',
  Referral = 'referral',
  Refund = 'refund',
  RefundDeduction = 'refund_deduction',
  Spend = 'spend',
  TeamPayout = 'team_payout',
  Topup = 'topup',
  Transfer = 'transfer'
}

export type UpdatePoolPrizeInput = {
  amount?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  ordering?: Maybe<Scalars['Int']>;
  tournamentId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of UpdatePoolPrize */
export type UpdatePoolPrizePayload = {
  __typename?: 'UpdatePoolPrizePayload';
  errors?: Maybe<Array<Error>>;
  poolPrize?: Maybe<PoolPrize>;
};

export type UpdateTournamentEventInput = {
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  eventType: Event;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
  state?: Maybe<Scalars['String']>;
  tournamentId: Scalars['ID'];
  tournamentTeamIds?: Maybe<Array<Scalars['ID']>>;
};

/** Autogenerated return type of UpdateTournamentEvent */
export type UpdateTournamentEventPayload = {
  __typename?: 'UpdateTournamentEventPayload';
  errors?: Maybe<Array<Error>>;
  tournamentEvent?: Maybe<TournamentEvent>;
};

export type UpdateTournamentInput = {
  apiKey?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['File']>;
  clanId?: Maybe<Scalars['ID']>;
  countryCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['Boolean']>;
  gameId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  introText?: Maybe<Scalars['String']>;
  invitationCode?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['File']>;
  mode?: Maybe<TournamentMode>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<TournamentState>;
};

/** Autogenerated return type of UpdateTournament */
export type UpdateTournamentPayload = {
  __typename?: 'UpdateTournamentPayload';
  errors?: Maybe<Array<Error>>;
  tournament?: Maybe<Tournament>;
};

export type UpdateTournamentRoomInput = {
  battleType?: Maybe<Scalars['String']>;
  checkIn?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  tournamentTeamIds?: Maybe<Array<Scalars['ID']>>;
};

/** Autogenerated return type of UpdateTournamentRoom */
export type UpdateTournamentRoomPayload = {
  __typename?: 'UpdateTournamentRoomPayload';
  errors?: Maybe<Array<Error>>;
  tournamentRoom?: Maybe<TournamentRoom>;
};

/** User */
export type User = {
  __typename?: 'User';
  _id: Scalars['ID'];
  adminNote?: Maybe<Scalars['String']>;
  allPrivateChatrooms: Array<Chatroom>;
  avatar?: Maybe<Scalars['String']>;
  avatarUrl?: Maybe<Scalars['String']>;
  bankInfo?: Maybe<BankInfo>;
  beKol: Scalars['Boolean'];
  bePro: Scalars['Boolean'];
  beStar: Scalars['Boolean'];
  birthday?: Maybe<Scalars['ISO8601DateTime']>;
  bookings?: Maybe<Array<Booking>>;
  bookingsRatingCount?: Maybe<Scalars['Float']>;
  career?: Maybe<Scalars['String']>;
  careerHighlights?: Maybe<Array<CareerHighlight>>;
  cartOrders?: Maybe<Cart>;
  chatroom?: Maybe<Chatroom>;
  chatroomId?: Maybe<Scalars['ID']>;
  clansCount: Scalars['Int'];
  club?: Maybe<Club>;
  completedBookingsCount: Scalars['Int'];
  countryCode: CountryCode;
  coverPictureUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  creditWallet: CreditWallet;
  currentJoinRequest?: Maybe<JoinRequest>;
  currentRealtime?: Maybe<PartneringRequest>;
  discordId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  followed: Scalars['Boolean'];
  followersCount: Scalars['Int'];
  followingCount: Scalars['Int'];
  fullName?: Maybe<Scalars['String']>;
  gameAccounts: Array<GameAccount>;
  gameBundles: Array<GameBundle>;
  gender?: Maybe<Gender>;
  icName?: Maybe<Scalars['String']>;
  icNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  indicators?: Maybe<Indicator>;
  introText?: Maybe<Scalars['String']>;
  isManager: Scalars['Boolean'];
  language: Language;
  lastSignInAt?: Maybe<Scalars['ISO8601DateTime']>;
  liked: Scalars['Boolean'];
  likesCount: Scalars['Int'];
  matchMakingBookings?: Maybe<Array<Booking>>;
  matchMakings?: Maybe<Array<MatchMaking>>;
  merchandises?: Maybe<Array<Merchandise>>;
  mlbbAccount?: Maybe<GameAccount>;
  name: Scalars['String'];
  nationality?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  notifications: Array<Notification>;
  officialName?: Maybe<Scalars['String']>;
  online: Scalars['Boolean'];
  orderHistory?: Maybe<Array<Order>>;
  overview?: Maybe<Scalars['String']>;
  partneringRequests: Array<PartneringRequest>;
  pendingJoinRequests?: Maybe<Array<JoinRequest>>;
  pendingPrize: Scalars['Int'];
  phoneCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postsCount: Scalars['Int'];
  privateChatroom?: Maybe<Chatroom>;
  promoCodes?: Maybe<Array<PromoCode>>;
  proPhotoUrl?: Maybe<Scalars['String']>;
  recentCombats?: Maybe<Array<CombatRoom>>;
  referralCode: Scalars['String'];
  referrals?: Maybe<Array<User>>;
  referrer?: Maybe<Referrer>;
  referrerId?: Maybe<Scalars['String']>;
  shippingAddresses?: Maybe<Array<ShippingAddress>>;
  signInCount: Scalars['Int'];
  suspend: Scalars['Boolean'];
  suspendGamerRequests?: Maybe<Array<SuspendGamerRequest>>;
  team?: Maybe<Team>;
  teamChatrooms?: Maybe<Array<Chatroom>>;
  teamInvitation?: Maybe<TeamInvitation>;
  teamInvitations: Array<TeamInvitation>;
  teamName?: Maybe<Scalars['String']>;
  teamRole?: Maybe<TeamRole>;
  themeColors?: Maybe<Array<Scalars['String']>>;
  updatedAt: Scalars['ISO8601DateTime'];
  userCreditTransaction: UserCreditTransactionCount;
  userIdApple?: Maybe<Scalars['String']>;
  userIdGoogle?: Maybe<Scalars['String']>;
  userIdInstagram?: Maybe<Scalars['String']>;
  userIdTiktok?: Maybe<Scalars['String']>;
  userMatchCount?: Maybe<Scalars['Int']>;
  userPrizes?: Maybe<Array<UserPrize>>;
  userPrizesCount: Scalars['Int'];
  userWinCount?: Maybe<Scalars['Int']>;
  verified: Scalars['Boolean'];
};


/** User */
export type UserChatroomArgs = {
  id: Scalars['ID'];
};


/** User */
export type UserMatchMakingBookingsArgs = {
  matchMakingId?: Maybe<Scalars['ID']>;
};


/** User */
export type UserMatchMakingsArgs = {
  page?: Maybe<Scalars['Int']>;
  state: Array<MatchMakingState>;
};


/** User */
export type UserNotificationsArgs = {
  gameId?: Maybe<Scalars['ID']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


/** User */
export type UserOrderHistoryArgs = {
  status: Array<OrderStatus>;
};


/** User */
export type UserPrivateChatroomArgs = {
  userId: Scalars['ID'];
};


/** User */
export type UserUserCreditTransactionArgs = {
  page?: Maybe<Scalars['Int']>;
};


/** User */
export type UserUserPrizesArgs = {
  page?: Maybe<Scalars['Int']>;
};

/** User Credit Transaction include counter */
export type UserCreditTransactionCount = {
  __typename?: 'UserCreditTransactionCount';
  count: Scalars['Int'];
  data: Array<CreditTransaction>;
};

/** User Game Account */
export type UserGameAccount = {
  __typename?: 'UserGameAccount';
  game: Game;
  id: Scalars['ID'];
  proficientHeros: Array<ProficientHero>;
};

/** User Prize */
export type UserPrize = {
  __typename?: 'UserPrize';
  address?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  creditTransactionId: Scalars['ID'];
  fullName?: Maybe<Scalars['String']>;
  icNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inGameId?: Maybe<Scalars['String']>;
  inGameName?: Maybe<Scalars['String']>;
  printName?: Maybe<Scalars['String']>;
  prize?: Maybe<Prize>;
  probabilities: Array<Scalars['String']>;
  promoCode?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  skinName?: Maybe<Scalars['String']>;
  state: UserPrizeState;
  userId: Scalars['ID'];
  userNickname: Scalars['String'];
};

export enum UserPrizeState {
  Cancelled = 'cancelled',
  Delivered = 'delivered',
  PendingInfo = 'pending_info',
  PendingSpin = 'pending_spin',
  Processing = 'processing'
}

export type BattleFragment = (
  { __typename?: 'Battle' }
  & Pick<Battle, 'id' | 'battleId' | 'startTime' | 'winCampId' | 'roomName' | 'state' | 'stateLeftTime' | 'gameTime' | 'dataId' | 'paused'>
  & { camps?: Maybe<Array<(
    { __typename?: 'Camp' }
    & CampFragment
  )>>, tournamentRoom: (
    { __typename?: 'TournamentRoom' }
    & Pick<TournamentRoom, 'id' | 'roomName'>
    & { tournamentEvent: (
      { __typename?: 'TournamentEvent' }
      & Pick<TournamentEvent, 'id'>
    ) }
  ) }
);

export type CampFragment = (
  { __typename?: 'Camp' }
  & Pick<Camp, 'id' | 'killLord' | 'killTower' | 'score' | 'teamName' | 'teamSimpleName' | 'totalMoney' | 'campId'>
  & { campPlayers?: Maybe<Array<(
    { __typename?: 'CampPlayer' }
    & CampPlayerFragment
  )>>, tournamentTeam?: Maybe<(
    { __typename?: 'TournamentTeam' }
    & TournamentTeamFragment
  )> }
);

export type CampPlayerFragment = (
  { __typename?: 'CampPlayer' }
  & Pick<CampPlayer, 'id' | 'assistNum' | 'avgFps' | 'banning' | 'bigReconnect' | 'clientIp' | 'damageEffect' | 'damagePercent' | 'dead' | 'deadNum' | 'equipList' | 'exp' | 'gold' | 'goldMap' | 'hitRate' | 'hurtPercent' | 'judger' | 'kda' | 'killNum' | 'level' | 'majorLeftTime' | 'mapPos' | 'minDamage' | 'minFps' | 'minMoney' | 'moneyPercent' | 'moveLag' | 'mvp' | 'name' | 'offeredRate' | 'picking' | 'pos' | 'reviveLeftTime' | 'runeMap' | 'score' | 'skillLeftTime' | 'skillUseTimes' | 'smallReconnect' | 'teamName' | 'teamSimpleName' | 'timeDelay' | 'totalDamage' | 'totalDamageTower' | 'totalHeal' | 'totalHurt' | 'totalMoney' | 'towerDamagePercent' | 'xpm' | 'createdAt' | 'updatedAt' | 'banHeroId' | 'heroId' | 'roleId' | 'runeId' | 'skillId' | 'skinId' | 'teamId' | 'zoneId'>
);

export type ClanFragment = (
  { __typename?: 'Clan' }
  & Pick<Clan, 'id' | 'name'>
);

export type ErrorFragment = (
  { __typename?: 'Error' }
  & Pick<Error, 'path' | 'detail'>
);

export type GameFragment = (
  { __typename?: 'Game' }
  & Pick<Game, 'id' | 'name' | 'shortname'>
);

export type BasicJudgeFragment = (
  { __typename?: 'Judge' }
  & Pick<Judge, 'id' | 'judgeId' | 'role'>
);

export type PoolPrizeFragment = (
  { __typename?: 'PoolPrize' }
  & Pick<PoolPrize, 'id' | 'name' | 'amount' | 'ordering' | 'createdAt' | 'updatedAt'>
  & { leaderboard?: Maybe<(
    { __typename?: 'Leaderboard' }
    & Pick<Leaderboard, 'id'>
  )>, combatTeam?: Maybe<(
    { __typename?: 'CombatTeam' }
    & Pick<CombatTeam, 'id'>
  )>, tournament?: Maybe<(
    { __typename?: 'Tournament' }
    & Pick<Tournament, 'id'>
  )> }
);

export type TeamFragment = (
  { __typename?: 'Team' }
  & Pick<Team, 'id' | 'name' | 'state' | 'icon'>
  & { tournamentTeams?: Maybe<Array<(
    { __typename?: 'TournamentTeam' }
    & Pick<TournamentTeam, 'id'>
  )>> }
);

export type ListTournamentFragment = (
  { __typename?: 'Tournament' }
  & Pick<Tournament, 'id' | 'name' | 'state' | 'countryCode' | 'logo' | 'backgroundImage' | 'mode' | 'apiKey'>
  & { game: (
    { __typename?: 'Game' }
    & Pick<Game, 'id' | 'name' | 'shortname'>
  ), clan?: Maybe<(
    { __typename?: 'Clan' }
    & Pick<Clan, 'id' | 'name'>
  )> }
);

export type TournamentFragment = (
  { __typename?: 'Tournament' }
  & Pick<Tournament, 'id' | 'name' | 'state' | 'countryCode' | 'logo' | 'backgroundImage' | 'mode' | 'apiKey'>
  & { game: (
    { __typename?: 'Game' }
    & Pick<Game, 'id' | 'name' | 'shortname'>
  ), clan?: Maybe<(
    { __typename?: 'Clan' }
    & Pick<Clan, 'id' | 'name'>
  )>, tournamentEvents?: Maybe<Array<(
    { __typename?: 'TournamentEvent' }
    & TournamentEventFragment
  )>>, poolPrizes?: Maybe<Array<(
    { __typename?: 'PoolPrize' }
    & PoolPrizeFragment
  )>> }
);

export type TournamentEventFragment = (
  { __typename?: 'TournamentEvent' }
  & Pick<TournamentEvent, 'id' | 'name' | 'startDate' | 'endDate' | 'position' | 'eventType' | 'state'>
  & { tournament: (
    { __typename?: 'Tournament' }
    & Pick<Tournament, 'id'>
  ), tournamentRooms?: Maybe<Array<(
    { __typename?: 'TournamentRoom' }
    & TournamentRoomFragment
  )>> }
);

export type TournamentRoomFragment = (
  { __typename?: 'TournamentRoom' }
  & Pick<TournamentRoom, 'id' | 'battleType' | 'roomName' | 'winTeamId' | 'checkIn' | 'state'>
  & { tournamentTeams?: Maybe<Array<(
    { __typename?: 'TournamentTeam' }
    & Pick<TournamentTeam, 'id' | 'teamName'>
    & { players: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'nickname'>
      & { club?: Maybe<(
        { __typename?: 'Club' }
        & Pick<Club, 'name'>
      )>, mlbbAccount?: Maybe<(
        { __typename?: 'GameAccount' }
        & Pick<GameAccount, 'id' | 'inGameName' | 'accountId'>
      )> }
    )>, team: (
      { __typename?: 'Team' }
      & TeamFragment
    ) }
  )>>, battles?: Maybe<Array<(
    { __typename?: 'Battle' }
    & BattleFragment
  )>> }
);

export type TournamentTeamFragment = (
  { __typename?: 'TournamentTeam' }
  & Pick<TournamentTeam, 'id' | 'state' | 'createdAt'>
  & { team: (
    { __typename?: 'Team' }
    & Pick<Team, 'id' | 'name'>
    & { club?: Maybe<(
      { __typename?: 'Club' }
      & Pick<Club, 'id' | 'name' | 'icon' | 'tagName' | 'countryCode'>
      & { manager: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'nickname' | 'email' | 'phoneNumber' | 'icName' | 'icNumber' | 'discordId'>
        & { gameAccounts: Array<(
          { __typename?: 'GameAccount' }
          & Pick<GameAccount, 'id' | 'accountId' | 'inGameName' | 'serverId'>
        )> }
      ) }
    )>, game: (
      { __typename?: 'Game' }
      & Pick<Game, 'id' | 'name'>
    ) }
  ), captain: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'phoneNumber' | 'nickname' | 'icName' | 'icNumber' | 'discordId'>
    & { gameAccounts: Array<(
      { __typename?: 'GameAccount' }
      & Pick<GameAccount, 'id' | 'accountId' | 'inGameName' | 'serverId'>
    )> }
  ), players: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'nickname' | 'email' | 'phoneNumber' | 'icName' | 'icNumber' | 'discordId'>
    & { gameAccounts: Array<(
      { __typename?: 'GameAccount' }
      & Pick<GameAccount, 'id' | 'accountId' | 'inGameName' | 'serverId'>
    )>, team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name'>
      & { club?: Maybe<(
        { __typename?: 'Club' }
        & Pick<Club, 'id' | 'name'>
      )> }
    )>, teamRole?: Maybe<(
      { __typename?: 'TeamRole' }
      & Pick<TeamRole, 'id'>
      & { proficientRole?: Maybe<(
        { __typename?: 'ProficientRole' }
        & Pick<ProficientRole, 'id' | 'name'>
      )> }
    )> }
  )>, subPlayers: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'nickname' | 'email' | 'phoneNumber' | 'icName' | 'icNumber' | 'discordId'>
    & { gameAccounts: Array<(
      { __typename?: 'GameAccount' }
      & Pick<GameAccount, 'id' | 'accountId' | 'inGameName' | 'serverId'>
    )>, team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name'>
      & { club?: Maybe<(
        { __typename?: 'Club' }
        & Pick<Club, 'id' | 'name'>
      )> }
    )>, teamRole?: Maybe<(
      { __typename?: 'TeamRole' }
      & Pick<TeamRole, 'id'>
      & { proficientRole?: Maybe<(
        { __typename?: 'ProficientRole' }
        & Pick<ProficientRole, 'id' | 'name'>
      )> }
    )> }
  )>, tournament: (
    { __typename?: 'Tournament' }
    & Pick<Tournament, 'id' | 'state' | 'name'>
  ) }
);

export type JudgeAuthorizationMutationVariables = Exact<{
  judgeId: Scalars['String'];
  password: Scalars['String'];
  ipAddress: Scalars['String'];
}>;


export type JudgeAuthorizationMutation = (
  { __typename?: 'Mutation' }
  & { judgeAuthorization?: Maybe<(
    { __typename?: 'JudgeAuthorizationPayload' }
    & Pick<JudgeAuthorizationPayload, 'jwt'>
    & { judge?: Maybe<(
      { __typename?: 'Judge' }
      & Pick<Judge, 'id' | 'judgeId' | 'role'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreatePoolPrizeMutationVariables = Exact<{
  name: Scalars['String'];
  amount: Scalars['Int'];
  tournamentId: Scalars['ID'];
}>;


export type CreatePoolPrizeMutation = (
  { __typename?: 'Mutation' }
  & { createPoolPrize?: Maybe<(
    { __typename?: 'CreatePoolPrizePayload' }
    & { poolPrize?: Maybe<(
      { __typename?: 'PoolPrize' }
      & Pick<PoolPrize, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type CreateTournamentMutationVariables = Exact<{
  name: Scalars['String'];
  countryCode: Scalars['String'];
  logo: Scalars['File'];
  backgroundImage: Scalars['File'];
  gameId: Scalars['ID'];
  clanId: Scalars['ID'];
  mode: TournamentMode;
  apiKey?: Maybe<Scalars['String']>;
}>;


export type CreateTournamentMutation = (
  { __typename?: 'Mutation' }
  & { createTournament?: Maybe<(
    { __typename?: 'CreateTournamentPayload' }
    & { tournament?: Maybe<(
      { __typename?: 'Tournament' }
      & Pick<Tournament, 'id' | 'name'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreateTournamentEventMutationVariables = Exact<{
  name: Scalars['String'];
  startDate: Scalars['ISO8601DateTime'];
  endDate: Scalars['ISO8601DateTime'];
  startTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  eventType: Event;
  position?: Maybe<Scalars['Int']>;
  tournamentId: Scalars['ID'];
}>;


export type CreateTournamentEventMutation = (
  { __typename?: 'Mutation' }
  & { createTournamentEvent?: Maybe<(
    { __typename?: 'CreateTournamentEventPayload' }
    & { tournamentEvent?: Maybe<(
      { __typename?: 'TournamentEvent' }
      & Pick<TournamentEvent, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type CreateTournamentRoomMutationVariables = Exact<{
  tournamentEventId: Scalars['ID'];
  tournamentTeamIds?: Maybe<Array<Scalars['ID']>>;
  checkIn?: Maybe<Scalars['ISO8601DateTime']>;
}>;


export type CreateTournamentRoomMutation = (
  { __typename?: 'Mutation' }
  & { createTournamentRoom?: Maybe<(
    { __typename?: 'CreateTournamentRoomPayload' }
    & { tournamentRoom?: Maybe<(
      { __typename?: 'TournamentRoom' }
      & TournamentRoomFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type DisqualifyTeamMutationVariables = Exact<{
  id: Scalars['ID'];
  winTeamId: Scalars['ID'];
}>;


export type DisqualifyTeamMutation = (
  { __typename?: 'Mutation' }
  & { completeTournamentRoom?: Maybe<(
    { __typename?: 'CompleteTournamentRoomPayload' }
    & { tournamentRoom?: Maybe<(
      { __typename?: 'TournamentRoom' }
      & Pick<TournamentRoom, 'id' | 'winTeamId'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type RemovePoolPrizeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemovePoolPrizeMutation = (
  { __typename?: 'Mutation' }
  & { removePoolPrize?: Maybe<(
    { __typename?: 'RemovePoolPrizePayload' }
    & Pick<RemovePoolPrizePayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type RemoveTournamentEventMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveTournamentEventMutation = (
  { __typename?: 'Mutation' }
  & { removeTournamentEvent?: Maybe<(
    { __typename?: 'RemoveTournamentEventPayload' }
    & Pick<RemoveTournamentEventPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type ReviewTournamentTeamMutationVariables = Exact<{
  id: Scalars['ID'];
  stateRequest: Scalars['String'];
}>;


export type ReviewTournamentTeamMutation = (
  { __typename?: 'Mutation' }
  & { reviewTournamentTeam?: Maybe<(
    { __typename?: 'ReviewTournamentTeamPayload' }
    & { tournamentTeam?: Maybe<(
      { __typename?: 'TournamentTeam' }
      & Pick<TournamentTeam, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type SubmitTournamentRoomNameMutationVariables = Exact<{
  id: Scalars['ID'];
  roomName: Scalars['String'];
}>;


export type SubmitTournamentRoomNameMutation = (
  { __typename?: 'Mutation' }
  & { submitTournamentRoomName?: Maybe<(
    { __typename?: 'SubmitTournamentRoomNamePayload' }
    & { tournamentRoom?: Maybe<(
      { __typename?: 'TournamentRoom' }
      & Pick<TournamentRoom, 'id' | 'roomName'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdatePoolPrizeMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  tournamentId?: Maybe<Scalars['ID']>;
  ordering?: Maybe<Scalars['Int']>;
}>;


export type UpdatePoolPrizeMutation = (
  { __typename?: 'Mutation' }
  & { updatePoolPrize?: Maybe<(
    { __typename?: 'UpdatePoolPrizePayload' }
    & { poolPrize?: Maybe<(
      { __typename?: 'PoolPrize' }
      & Pick<PoolPrize, 'id' | 'name' | 'amount' | 'ordering'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type UpdateTournamentMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['File']>;
  backgroundImage?: Maybe<Scalars['File']>;
  gameId?: Maybe<Scalars['ID']>;
  clanId?: Maybe<Scalars['ID']>;
  mode?: Maybe<TournamentMode>;
  apiKey?: Maybe<Scalars['String']>;
}>;


export type UpdateTournamentMutation = (
  { __typename?: 'Mutation' }
  & { updateTournament?: Maybe<(
    { __typename?: 'UpdateTournamentPayload' }
    & { tournament?: Maybe<(
      { __typename?: 'Tournament' }
      & Pick<Tournament, 'id' | 'name'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateTournamentEventMutationVariables = Exact<{
  id: Scalars['ID'];
  tournamentId: Scalars['ID'];
  tournamentTeamIds?: Maybe<Array<Scalars['ID']>>;
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  name?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  eventType: Event;
  state?: Maybe<Scalars['String']>;
}>;


export type UpdateTournamentEventMutation = (
  { __typename?: 'Mutation' }
  & { updateTournamentEvent?: Maybe<(
    { __typename?: 'UpdateTournamentEventPayload' }
    & { tournamentEvent?: Maybe<(
      { __typename?: 'TournamentEvent' }
      & Pick<TournamentEvent, 'id' | 'name' | 'startDate' | 'endDate' | 'position' | 'eventType' | 'state'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type UpdateTournamentRoomMutationVariables = Exact<{
  id: Scalars['ID'];
  tournamentTeamIds?: Maybe<Array<Scalars['ID']>>;
  checkIn?: Maybe<Scalars['ISO8601DateTime']>;
  battleType?: Maybe<Scalars['String']>;
}>;


export type UpdateTournamentRoomMutation = (
  { __typename?: 'Mutation' }
  & { updateTournamentRoom?: Maybe<(
    { __typename?: 'UpdateTournamentRoomPayload' }
    & { tournamentRoom?: Maybe<(
      { __typename?: 'TournamentRoom' }
      & TournamentRoomFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type BattlesQueryVariables = Exact<{
  tournamentRoomId: Scalars['ID'];
}>;


export type BattlesQuery = (
  { __typename?: 'Query' }
  & { battles: Array<(
    { __typename?: 'Battle' }
    & BattleFragment
  )> }
);

export type BattleIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type BattleIdQuery = (
  { __typename?: 'Query' }
  & { battle: (
    { __typename?: 'Battle' }
    & BattleFragment
  ) }
);

export type CampsQueryVariables = Exact<{
  battleId: Scalars['ID'];
}>;


export type CampsQuery = (
  { __typename?: 'Query' }
  & { camps: Array<(
    { __typename?: 'Camp' }
    & CampFragment
  )> }
);

export type CampIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CampIdQuery = (
  { __typename?: 'Query' }
  & { camp: (
    { __typename?: 'Camp' }
    & CampFragment
  ) }
);

export type CampPlayersQueryVariables = Exact<{
  campId: Scalars['ID'];
}>;


export type CampPlayersQuery = (
  { __typename?: 'Query' }
  & { campPlayers: Array<(
    { __typename?: 'CampPlayer' }
    & CampPlayerFragment
  )> }
);

export type CampPlayerIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CampPlayerIdQuery = (
  { __typename?: 'Query' }
  & { campPlayer: (
    { __typename?: 'CampPlayer' }
    & CampPlayerFragment
  ) }
);

export type ClansQueryVariables = Exact<{ [key: string]: never; }>;


export type ClansQuery = (
  { __typename?: 'Query' }
  & { clans?: Maybe<Array<(
    { __typename?: 'Clan' }
    & ClanFragment
  )>> }
);

export type GamesQueryVariables = Exact<{ [key: string]: never; }>;


export type GamesQuery = (
  { __typename?: 'Query' }
  & { games?: Maybe<Array<(
    { __typename?: 'Game' }
    & GameFragment
  )>> }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Judge' }
    & BasicJudgeFragment
  )> }
);

export type TeamsQueryVariables = Exact<{
  tournamentEventId: Scalars['ID'];
  tournamentTeamState?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
}>;


export type TeamsQuery = (
  { __typename?: 'Query' }
  & { teams: Array<(
    { __typename?: 'Team' }
    & TeamFragment
  )> }
);

export type TournamentsQueryVariables = Exact<{
  gameId?: Maybe<Scalars['ID']>;
}>;


export type TournamentsQuery = (
  { __typename?: 'Query' }
  & { tournaments: Array<(
    { __typename?: 'Tournament' }
    & ListTournamentFragment
  )> }
);

export type TournamentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TournamentQuery = (
  { __typename?: 'Query' }
  & { tournament: (
    { __typename?: 'Tournament' }
    & TournamentFragment
  ) }
);

export type TournamentEventsQueryVariables = Exact<{
  tournamentId: Scalars['ID'];
}>;


export type TournamentEventsQuery = (
  { __typename?: 'Query' }
  & { tournamentEvents: Array<(
    { __typename?: 'TournamentEvent' }
    & TournamentEventFragment
  )> }
);

export type TournamentEventQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TournamentEventQuery = (
  { __typename?: 'Query' }
  & { tournamentEvent: (
    { __typename?: 'TournamentEvent' }
    & TournamentEventFragment
  ) }
);

export type TournamentRoomsQueryVariables = Exact<{
  tournamentEventId: Scalars['ID'];
}>;


export type TournamentRoomsQuery = (
  { __typename?: 'Query' }
  & { tournamentRooms: Array<(
    { __typename?: 'TournamentRoom' }
    & TournamentRoomFragment
  )> }
);

export type TournamentRoomIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TournamentRoomIdQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Judge' }
    & BasicJudgeFragment
  )>, tournamentRoom: (
    { __typename?: 'TournamentRoom' }
    & TournamentRoomFragment
  ) }
);

export type TournamentTeamAccountsQueryVariables = Exact<{
  tournamentId?: Maybe<Scalars['ID']>;
  state?: Maybe<Scalars['String']>;
  page: Scalars['Int'];
}>;


export type TournamentTeamAccountsQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Judge' }
    & BasicJudgeFragment
  )>, tournamentTeamAccounts?: Maybe<(
    { __typename?: 'TournamentTeamCount' }
    & Pick<TournamentTeamCount, 'count'>
    & { data: Array<(
      { __typename?: 'TournamentTeam' }
      & TournamentTeamFragment
    )> }
  )> }
);

export type TournamentTeamIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TournamentTeamIdQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Judge' }
    & BasicJudgeFragment
  )>, tournamentTeam: (
    { __typename?: 'TournamentTeam' }
    & TournamentTeamFragment
  ) }
);

export type TournamentTeamCsvQueryVariables = Exact<{
  tournamentId: Scalars['ID'];
}>;


export type TournamentTeamCsvQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'tournamentTeamCsv'>
);

export const ClanFragmentDoc = gql`
    fragment Clan on Clan {
  id
  name
}
    `;
export const ErrorFragmentDoc = gql`
    fragment Error on Error {
  path
  detail
}
    `;
export const GameFragmentDoc = gql`
    fragment Game on Game {
  id
  name
  shortname
}
    `;
export const BasicJudgeFragmentDoc = gql`
    fragment BasicJudge on Judge {
  id
  judgeId
  role
}
    `;
export const ListTournamentFragmentDoc = gql`
    fragment ListTournament on Tournament {
  id
  name
  state
  countryCode
  logo
  backgroundImage
  mode
  apiKey
  game {
    id
    name
    shortname
  }
  clan {
    id
    name
  }
}
    `;
export const TeamFragmentDoc = gql`
    fragment Team on Team {
  id
  name
  state
  icon
  tournamentTeams {
    id
  }
}
    `;
export const CampPlayerFragmentDoc = gql`
    fragment CampPlayer on CampPlayer {
  id
  assistNum
  avgFps
  banning
  bigReconnect
  clientIp
  damageEffect
  damagePercent
  dead
  deadNum
  equipList
  exp
  gold
  goldMap
  hitRate
  hurtPercent
  judger
  kda
  killNum
  level
  majorLeftTime
  mapPos
  minDamage
  minFps
  minMoney
  moneyPercent
  moveLag
  mvp
  name
  offeredRate
  picking
  pos
  reviveLeftTime
  runeMap
  score
  skillLeftTime
  skillUseTimes
  smallReconnect
  teamName
  teamSimpleName
  timeDelay
  totalDamage
  totalDamageTower
  totalHeal
  totalHurt
  totalMoney
  towerDamagePercent
  totalHurt
  xpm
  createdAt
  updatedAt
  banHeroId
  heroId
  roleId
  runeId
  skillId
  skinId
  teamId
  zoneId
}
    `;
export const TournamentTeamFragmentDoc = gql`
    fragment TournamentTeam on TournamentTeam {
  id
  team {
    id
    name
    club {
      id
      name
      icon
      tagName
      countryCode
      manager {
        id
        nickname
        email
        phoneNumber
        icName
        icNumber
        discordId
        gameAccounts {
          id
          accountId
          inGameName
          serverId
        }
      }
    }
    game {
      id
      name
    }
  }
  state
  captain {
    id
    email
    phoneNumber
    nickname
    icName
    icNumber
    discordId
    gameAccounts {
      id
      accountId
      inGameName
      serverId
    }
  }
  players {
    id
    nickname
    email
    phoneNumber
    icName
    icNumber
    discordId
    gameAccounts {
      id
      accountId
      inGameName
      serverId
    }
    team {
      id
      name
      club {
        id
        name
      }
    }
    teamRole {
      id
      proficientRole {
        id
        name
      }
    }
  }
  subPlayers {
    id
    nickname
    email
    phoneNumber
    icName
    icNumber
    discordId
    gameAccounts {
      id
      accountId
      inGameName
      serverId
    }
    team {
      id
      name
      club {
        id
        name
      }
    }
    teamRole {
      id
      proficientRole {
        id
        name
      }
    }
  }
  tournament {
    id
    state
    name
  }
  createdAt
}
    `;
export const CampFragmentDoc = gql`
    fragment Camp on Camp {
  id
  killLord
  killTower
  score
  teamName
  teamSimpleName
  totalMoney
  campPlayers {
    ...CampPlayer
  }
  campId
  tournamentTeam {
    ...TournamentTeam
  }
}
    ${CampPlayerFragmentDoc}
${TournamentTeamFragmentDoc}`;
export const BattleFragmentDoc = gql`
    fragment Battle on Battle {
  id
  battleId
  startTime
  winCampId
  roomName
  state
  stateLeftTime
  gameTime
  dataId
  paused
  camps {
    ...Camp
  }
  tournamentRoom {
    id
    roomName
    tournamentEvent {
      id
    }
  }
}
    ${CampFragmentDoc}`;
export const TournamentRoomFragmentDoc = gql`
    fragment TournamentRoom on TournamentRoom {
  id
  battleType
  roomName
  winTeamId
  checkIn
  state
  tournamentTeams {
    id
    teamName
    players {
      id
      nickname
      club {
        name
      }
      mlbbAccount {
        id
        inGameName
        accountId
      }
    }
    team {
      ...Team
    }
  }
  battles {
    ...Battle
  }
}
    ${TeamFragmentDoc}
${BattleFragmentDoc}`;
export const TournamentEventFragmentDoc = gql`
    fragment TournamentEvent on TournamentEvent {
  id
  name
  startDate
  endDate
  position
  eventType
  state
  tournament {
    id
  }
  tournamentRooms {
    ...TournamentRoom
  }
}
    ${TournamentRoomFragmentDoc}`;
export const PoolPrizeFragmentDoc = gql`
    fragment PoolPrize on PoolPrize {
  id
  name
  amount
  ordering
  leaderboard {
    id
  }
  combatTeam {
    id
  }
  tournament {
    id
  }
  createdAt
  updatedAt
}
    `;
export const TournamentFragmentDoc = gql`
    fragment Tournament on Tournament {
  id
  name
  state
  countryCode
  logo
  backgroundImage
  mode
  apiKey
  game {
    id
    name
    shortname
  }
  clan {
    id
    name
  }
  tournamentEvents {
    ...TournamentEvent
  }
  poolPrizes {
    ...PoolPrize
  }
}
    ${TournamentEventFragmentDoc}
${PoolPrizeFragmentDoc}`;
export const JudgeAuthorizationDocument = gql`
    mutation JudgeAuthorization($judgeId: String!, $password: String!, $ipAddress: String!) {
  judgeAuthorization(input: {judgeId: $judgeId, password: $password, ipAddress: $ipAddress}) {
    jwt
    judge {
      id
      judgeId
      role
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type JudgeAuthorizationMutationFn = Apollo.MutationFunction<JudgeAuthorizationMutation, JudgeAuthorizationMutationVariables>;

/**
 * __useJudgeAuthorizationMutation__
 *
 * To run a mutation, you first call `useJudgeAuthorizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJudgeAuthorizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [judgeAuthorizationMutation, { data, loading, error }] = useJudgeAuthorizationMutation({
 *   variables: {
 *      judgeId: // value for 'judgeId'
 *      password: // value for 'password'
 *      ipAddress: // value for 'ipAddress'
 *   },
 * });
 */
export function useJudgeAuthorizationMutation(baseOptions?: Apollo.MutationHookOptions<JudgeAuthorizationMutation, JudgeAuthorizationMutationVariables>) {
        return Apollo.useMutation<JudgeAuthorizationMutation, JudgeAuthorizationMutationVariables>(JudgeAuthorizationDocument, baseOptions);
      }
export type JudgeAuthorizationMutationHookResult = ReturnType<typeof useJudgeAuthorizationMutation>;
export type JudgeAuthorizationMutationResult = Apollo.MutationResult<JudgeAuthorizationMutation>;
export type JudgeAuthorizationMutationOptions = Apollo.BaseMutationOptions<JudgeAuthorizationMutation, JudgeAuthorizationMutationVariables>;
export const CreatePoolPrizeDocument = gql`
    mutation CreatePoolPrize($name: String!, $amount: Int!, $tournamentId: ID!) {
  createPoolPrize(input: {name: $name, amount: $amount, tournamentId: $tournamentId}) {
    poolPrize {
      id
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type CreatePoolPrizeMutationFn = Apollo.MutationFunction<CreatePoolPrizeMutation, CreatePoolPrizeMutationVariables>;

/**
 * __useCreatePoolPrizeMutation__
 *
 * To run a mutation, you first call `useCreatePoolPrizeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePoolPrizeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPoolPrizeMutation, { data, loading, error }] = useCreatePoolPrizeMutation({
 *   variables: {
 *      name: // value for 'name'
 *      amount: // value for 'amount'
 *      tournamentId: // value for 'tournamentId'
 *   },
 * });
 */
export function useCreatePoolPrizeMutation(baseOptions?: Apollo.MutationHookOptions<CreatePoolPrizeMutation, CreatePoolPrizeMutationVariables>) {
        return Apollo.useMutation<CreatePoolPrizeMutation, CreatePoolPrizeMutationVariables>(CreatePoolPrizeDocument, baseOptions);
      }
export type CreatePoolPrizeMutationHookResult = ReturnType<typeof useCreatePoolPrizeMutation>;
export type CreatePoolPrizeMutationResult = Apollo.MutationResult<CreatePoolPrizeMutation>;
export type CreatePoolPrizeMutationOptions = Apollo.BaseMutationOptions<CreatePoolPrizeMutation, CreatePoolPrizeMutationVariables>;
export const CreateTournamentDocument = gql`
    mutation CreateTournament($name: String!, $countryCode: String!, $logo: File!, $backgroundImage: File!, $gameId: ID!, $clanId: ID!, $mode: TournamentMode!, $apiKey: String) {
  createTournament(input: {name: $name, countryCode: $countryCode, logo: $logo, backgroundImage: $backgroundImage, gameId: $gameId, clanId: $clanId, mode: $mode, apiKey: $apiKey}) {
    tournament {
      id
      name
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CreateTournamentMutationFn = Apollo.MutationFunction<CreateTournamentMutation, CreateTournamentMutationVariables>;

/**
 * __useCreateTournamentMutation__
 *
 * To run a mutation, you first call `useCreateTournamentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTournamentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTournamentMutation, { data, loading, error }] = useCreateTournamentMutation({
 *   variables: {
 *      name: // value for 'name'
 *      countryCode: // value for 'countryCode'
 *      logo: // value for 'logo'
 *      backgroundImage: // value for 'backgroundImage'
 *      gameId: // value for 'gameId'
 *      clanId: // value for 'clanId'
 *      mode: // value for 'mode'
 *      apiKey: // value for 'apiKey'
 *   },
 * });
 */
export function useCreateTournamentMutation(baseOptions?: Apollo.MutationHookOptions<CreateTournamentMutation, CreateTournamentMutationVariables>) {
        return Apollo.useMutation<CreateTournamentMutation, CreateTournamentMutationVariables>(CreateTournamentDocument, baseOptions);
      }
export type CreateTournamentMutationHookResult = ReturnType<typeof useCreateTournamentMutation>;
export type CreateTournamentMutationResult = Apollo.MutationResult<CreateTournamentMutation>;
export type CreateTournamentMutationOptions = Apollo.BaseMutationOptions<CreateTournamentMutation, CreateTournamentMutationVariables>;
export const CreateTournamentEventDocument = gql`
    mutation CreateTournamentEvent($name: String!, $startDate: ISO8601DateTime!, $endDate: ISO8601DateTime!, $startTime: String, $endTime: String, $eventType: Event!, $position: Int, $tournamentId: ID!) {
  createTournamentEvent(input: {name: $name, startDate: $startDate, endDate: $endDate, startTime: $startTime, endTime: $endTime, eventType: $eventType, position: $position, tournamentId: $tournamentId}) {
    tournamentEvent {
      id
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type CreateTournamentEventMutationFn = Apollo.MutationFunction<CreateTournamentEventMutation, CreateTournamentEventMutationVariables>;

/**
 * __useCreateTournamentEventMutation__
 *
 * To run a mutation, you first call `useCreateTournamentEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTournamentEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTournamentEventMutation, { data, loading, error }] = useCreateTournamentEventMutation({
 *   variables: {
 *      name: // value for 'name'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      eventType: // value for 'eventType'
 *      position: // value for 'position'
 *      tournamentId: // value for 'tournamentId'
 *   },
 * });
 */
export function useCreateTournamentEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateTournamentEventMutation, CreateTournamentEventMutationVariables>) {
        return Apollo.useMutation<CreateTournamentEventMutation, CreateTournamentEventMutationVariables>(CreateTournamentEventDocument, baseOptions);
      }
export type CreateTournamentEventMutationHookResult = ReturnType<typeof useCreateTournamentEventMutation>;
export type CreateTournamentEventMutationResult = Apollo.MutationResult<CreateTournamentEventMutation>;
export type CreateTournamentEventMutationOptions = Apollo.BaseMutationOptions<CreateTournamentEventMutation, CreateTournamentEventMutationVariables>;
export const CreateTournamentRoomDocument = gql`
    mutation CreateTournamentRoom($tournamentEventId: ID!, $tournamentTeamIds: [ID!], $checkIn: ISO8601DateTime) {
  createTournamentRoom(input: {tournamentEventId: $tournamentEventId, tournamentTeamIds: $tournamentTeamIds, checkIn: $checkIn}) {
    tournamentRoom {
      ...TournamentRoom
    }
    errors {
      path
      detail
    }
  }
}
    ${TournamentRoomFragmentDoc}`;
export type CreateTournamentRoomMutationFn = Apollo.MutationFunction<CreateTournamentRoomMutation, CreateTournamentRoomMutationVariables>;

/**
 * __useCreateTournamentRoomMutation__
 *
 * To run a mutation, you first call `useCreateTournamentRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTournamentRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTournamentRoomMutation, { data, loading, error }] = useCreateTournamentRoomMutation({
 *   variables: {
 *      tournamentEventId: // value for 'tournamentEventId'
 *      tournamentTeamIds: // value for 'tournamentTeamIds'
 *      checkIn: // value for 'checkIn'
 *   },
 * });
 */
export function useCreateTournamentRoomMutation(baseOptions?: Apollo.MutationHookOptions<CreateTournamentRoomMutation, CreateTournamentRoomMutationVariables>) {
        return Apollo.useMutation<CreateTournamentRoomMutation, CreateTournamentRoomMutationVariables>(CreateTournamentRoomDocument, baseOptions);
      }
export type CreateTournamentRoomMutationHookResult = ReturnType<typeof useCreateTournamentRoomMutation>;
export type CreateTournamentRoomMutationResult = Apollo.MutationResult<CreateTournamentRoomMutation>;
export type CreateTournamentRoomMutationOptions = Apollo.BaseMutationOptions<CreateTournamentRoomMutation, CreateTournamentRoomMutationVariables>;
export const DisqualifyTeamDocument = gql`
    mutation DisqualifyTeam($id: ID!, $winTeamId: ID!) {
  completeTournamentRoom(input: {id: $id, winTeamId: $winTeamId}) {
    tournamentRoom {
      id
      winTeamId
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type DisqualifyTeamMutationFn = Apollo.MutationFunction<DisqualifyTeamMutation, DisqualifyTeamMutationVariables>;

/**
 * __useDisqualifyTeamMutation__
 *
 * To run a mutation, you first call `useDisqualifyTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisqualifyTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disqualifyTeamMutation, { data, loading, error }] = useDisqualifyTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *      winTeamId: // value for 'winTeamId'
 *   },
 * });
 */
export function useDisqualifyTeamMutation(baseOptions?: Apollo.MutationHookOptions<DisqualifyTeamMutation, DisqualifyTeamMutationVariables>) {
        return Apollo.useMutation<DisqualifyTeamMutation, DisqualifyTeamMutationVariables>(DisqualifyTeamDocument, baseOptions);
      }
export type DisqualifyTeamMutationHookResult = ReturnType<typeof useDisqualifyTeamMutation>;
export type DisqualifyTeamMutationResult = Apollo.MutationResult<DisqualifyTeamMutation>;
export type DisqualifyTeamMutationOptions = Apollo.BaseMutationOptions<DisqualifyTeamMutation, DisqualifyTeamMutationVariables>;
export const RemovePoolPrizeDocument = gql`
    mutation RemovePoolPrize($id: ID!) {
  removePoolPrize(input: {id: $id}) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type RemovePoolPrizeMutationFn = Apollo.MutationFunction<RemovePoolPrizeMutation, RemovePoolPrizeMutationVariables>;

/**
 * __useRemovePoolPrizeMutation__
 *
 * To run a mutation, you first call `useRemovePoolPrizeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePoolPrizeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePoolPrizeMutation, { data, loading, error }] = useRemovePoolPrizeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemovePoolPrizeMutation(baseOptions?: Apollo.MutationHookOptions<RemovePoolPrizeMutation, RemovePoolPrizeMutationVariables>) {
        return Apollo.useMutation<RemovePoolPrizeMutation, RemovePoolPrizeMutationVariables>(RemovePoolPrizeDocument, baseOptions);
      }
export type RemovePoolPrizeMutationHookResult = ReturnType<typeof useRemovePoolPrizeMutation>;
export type RemovePoolPrizeMutationResult = Apollo.MutationResult<RemovePoolPrizeMutation>;
export type RemovePoolPrizeMutationOptions = Apollo.BaseMutationOptions<RemovePoolPrizeMutation, RemovePoolPrizeMutationVariables>;
export const RemoveTournamentEventDocument = gql`
    mutation RemoveTournamentEvent($id: ID!) {
  removeTournamentEvent(input: {id: $id}) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type RemoveTournamentEventMutationFn = Apollo.MutationFunction<RemoveTournamentEventMutation, RemoveTournamentEventMutationVariables>;

/**
 * __useRemoveTournamentEventMutation__
 *
 * To run a mutation, you first call `useRemoveTournamentEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTournamentEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTournamentEventMutation, { data, loading, error }] = useRemoveTournamentEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveTournamentEventMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTournamentEventMutation, RemoveTournamentEventMutationVariables>) {
        return Apollo.useMutation<RemoveTournamentEventMutation, RemoveTournamentEventMutationVariables>(RemoveTournamentEventDocument, baseOptions);
      }
export type RemoveTournamentEventMutationHookResult = ReturnType<typeof useRemoveTournamentEventMutation>;
export type RemoveTournamentEventMutationResult = Apollo.MutationResult<RemoveTournamentEventMutation>;
export type RemoveTournamentEventMutationOptions = Apollo.BaseMutationOptions<RemoveTournamentEventMutation, RemoveTournamentEventMutationVariables>;
export const ReviewTournamentTeamDocument = gql`
    mutation ReviewTournamentTeam($id: ID!, $stateRequest: String!) {
  reviewTournamentTeam(input: {id: $id, stateRequest: $stateRequest}) {
    tournamentTeam {
      id
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type ReviewTournamentTeamMutationFn = Apollo.MutationFunction<ReviewTournamentTeamMutation, ReviewTournamentTeamMutationVariables>;

/**
 * __useReviewTournamentTeamMutation__
 *
 * To run a mutation, you first call `useReviewTournamentTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewTournamentTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewTournamentTeamMutation, { data, loading, error }] = useReviewTournamentTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *      stateRequest: // value for 'stateRequest'
 *   },
 * });
 */
export function useReviewTournamentTeamMutation(baseOptions?: Apollo.MutationHookOptions<ReviewTournamentTeamMutation, ReviewTournamentTeamMutationVariables>) {
        return Apollo.useMutation<ReviewTournamentTeamMutation, ReviewTournamentTeamMutationVariables>(ReviewTournamentTeamDocument, baseOptions);
      }
export type ReviewTournamentTeamMutationHookResult = ReturnType<typeof useReviewTournamentTeamMutation>;
export type ReviewTournamentTeamMutationResult = Apollo.MutationResult<ReviewTournamentTeamMutation>;
export type ReviewTournamentTeamMutationOptions = Apollo.BaseMutationOptions<ReviewTournamentTeamMutation, ReviewTournamentTeamMutationVariables>;
export const SubmitTournamentRoomNameDocument = gql`
    mutation SubmitTournamentRoomName($id: ID!, $roomName: String!) {
  submitTournamentRoomName(input: {id: $id, roomName: $roomName}) {
    tournamentRoom {
      id
      roomName
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type SubmitTournamentRoomNameMutationFn = Apollo.MutationFunction<SubmitTournamentRoomNameMutation, SubmitTournamentRoomNameMutationVariables>;

/**
 * __useSubmitTournamentRoomNameMutation__
 *
 * To run a mutation, you first call `useSubmitTournamentRoomNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitTournamentRoomNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitTournamentRoomNameMutation, { data, loading, error }] = useSubmitTournamentRoomNameMutation({
 *   variables: {
 *      id: // value for 'id'
 *      roomName: // value for 'roomName'
 *   },
 * });
 */
export function useSubmitTournamentRoomNameMutation(baseOptions?: Apollo.MutationHookOptions<SubmitTournamentRoomNameMutation, SubmitTournamentRoomNameMutationVariables>) {
        return Apollo.useMutation<SubmitTournamentRoomNameMutation, SubmitTournamentRoomNameMutationVariables>(SubmitTournamentRoomNameDocument, baseOptions);
      }
export type SubmitTournamentRoomNameMutationHookResult = ReturnType<typeof useSubmitTournamentRoomNameMutation>;
export type SubmitTournamentRoomNameMutationResult = Apollo.MutationResult<SubmitTournamentRoomNameMutation>;
export type SubmitTournamentRoomNameMutationOptions = Apollo.BaseMutationOptions<SubmitTournamentRoomNameMutation, SubmitTournamentRoomNameMutationVariables>;
export const UpdatePoolPrizeDocument = gql`
    mutation UpdatePoolPrize($id: ID!, $name: String, $amount: Int, $tournamentId: ID, $ordering: Int) {
  updatePoolPrize(input: {id: $id, name: $name, amount: $amount, tournamentId: $tournamentId, ordering: $ordering}) {
    poolPrize {
      id
      name
      amount
      ordering
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type UpdatePoolPrizeMutationFn = Apollo.MutationFunction<UpdatePoolPrizeMutation, UpdatePoolPrizeMutationVariables>;

/**
 * __useUpdatePoolPrizeMutation__
 *
 * To run a mutation, you first call `useUpdatePoolPrizeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePoolPrizeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePoolPrizeMutation, { data, loading, error }] = useUpdatePoolPrizeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      amount: // value for 'amount'
 *      tournamentId: // value for 'tournamentId'
 *      ordering: // value for 'ordering'
 *   },
 * });
 */
export function useUpdatePoolPrizeMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePoolPrizeMutation, UpdatePoolPrizeMutationVariables>) {
        return Apollo.useMutation<UpdatePoolPrizeMutation, UpdatePoolPrizeMutationVariables>(UpdatePoolPrizeDocument, baseOptions);
      }
export type UpdatePoolPrizeMutationHookResult = ReturnType<typeof useUpdatePoolPrizeMutation>;
export type UpdatePoolPrizeMutationResult = Apollo.MutationResult<UpdatePoolPrizeMutation>;
export type UpdatePoolPrizeMutationOptions = Apollo.BaseMutationOptions<UpdatePoolPrizeMutation, UpdatePoolPrizeMutationVariables>;
export const UpdateTournamentDocument = gql`
    mutation UpdateTournament($id: ID!, $name: String, $countryCode: String, $logo: File, $backgroundImage: File, $gameId: ID, $clanId: ID, $mode: TournamentMode, $apiKey: String) {
  updateTournament(input: {id: $id, name: $name, countryCode: $countryCode, logo: $logo, backgroundImage: $backgroundImage, gameId: $gameId, clanId: $clanId, mode: $mode, apiKey: $apiKey}) {
    tournament {
      id
      name
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateTournamentMutationFn = Apollo.MutationFunction<UpdateTournamentMutation, UpdateTournamentMutationVariables>;

/**
 * __useUpdateTournamentMutation__
 *
 * To run a mutation, you first call `useUpdateTournamentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTournamentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTournamentMutation, { data, loading, error }] = useUpdateTournamentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      countryCode: // value for 'countryCode'
 *      logo: // value for 'logo'
 *      backgroundImage: // value for 'backgroundImage'
 *      gameId: // value for 'gameId'
 *      clanId: // value for 'clanId'
 *      mode: // value for 'mode'
 *      apiKey: // value for 'apiKey'
 *   },
 * });
 */
export function useUpdateTournamentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTournamentMutation, UpdateTournamentMutationVariables>) {
        return Apollo.useMutation<UpdateTournamentMutation, UpdateTournamentMutationVariables>(UpdateTournamentDocument, baseOptions);
      }
export type UpdateTournamentMutationHookResult = ReturnType<typeof useUpdateTournamentMutation>;
export type UpdateTournamentMutationResult = Apollo.MutationResult<UpdateTournamentMutation>;
export type UpdateTournamentMutationOptions = Apollo.BaseMutationOptions<UpdateTournamentMutation, UpdateTournamentMutationVariables>;
export const UpdateTournamentEventDocument = gql`
    mutation UpdateTournamentEvent($id: ID!, $tournamentId: ID!, $tournamentTeamIds: [ID!], $startDate: ISO8601DateTime, $endDate: ISO8601DateTime, $name: String, $position: Int!, $eventType: Event!, $state: String) {
  updateTournamentEvent(input: {id: $id, tournamentId: $tournamentId, tournamentTeamIds: $tournamentTeamIds, name: $name, startDate: $startDate, endDate: $endDate, position: $position, eventType: $eventType, state: $state}) {
    tournamentEvent {
      id
      name
      startDate
      endDate
      position
      eventType
      state
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type UpdateTournamentEventMutationFn = Apollo.MutationFunction<UpdateTournamentEventMutation, UpdateTournamentEventMutationVariables>;

/**
 * __useUpdateTournamentEventMutation__
 *
 * To run a mutation, you first call `useUpdateTournamentEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTournamentEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTournamentEventMutation, { data, loading, error }] = useUpdateTournamentEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      tournamentId: // value for 'tournamentId'
 *      tournamentTeamIds: // value for 'tournamentTeamIds'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      name: // value for 'name'
 *      position: // value for 'position'
 *      eventType: // value for 'eventType'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useUpdateTournamentEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTournamentEventMutation, UpdateTournamentEventMutationVariables>) {
        return Apollo.useMutation<UpdateTournamentEventMutation, UpdateTournamentEventMutationVariables>(UpdateTournamentEventDocument, baseOptions);
      }
export type UpdateTournamentEventMutationHookResult = ReturnType<typeof useUpdateTournamentEventMutation>;
export type UpdateTournamentEventMutationResult = Apollo.MutationResult<UpdateTournamentEventMutation>;
export type UpdateTournamentEventMutationOptions = Apollo.BaseMutationOptions<UpdateTournamentEventMutation, UpdateTournamentEventMutationVariables>;
export const UpdateTournamentRoomDocument = gql`
    mutation UpdateTournamentRoom($id: ID!, $tournamentTeamIds: [ID!], $checkIn: ISO8601DateTime, $battleType: String) {
  updateTournamentRoom(input: {id: $id, tournamentTeamIds: $tournamentTeamIds, checkIn: $checkIn, battleType: $battleType}) {
    tournamentRoom {
      ...TournamentRoom
    }
    errors {
      path
      detail
    }
  }
}
    ${TournamentRoomFragmentDoc}`;
export type UpdateTournamentRoomMutationFn = Apollo.MutationFunction<UpdateTournamentRoomMutation, UpdateTournamentRoomMutationVariables>;

/**
 * __useUpdateTournamentRoomMutation__
 *
 * To run a mutation, you first call `useUpdateTournamentRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTournamentRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTournamentRoomMutation, { data, loading, error }] = useUpdateTournamentRoomMutation({
 *   variables: {
 *      id: // value for 'id'
 *      tournamentTeamIds: // value for 'tournamentTeamIds'
 *      checkIn: // value for 'checkIn'
 *      battleType: // value for 'battleType'
 *   },
 * });
 */
export function useUpdateTournamentRoomMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTournamentRoomMutation, UpdateTournamentRoomMutationVariables>) {
        return Apollo.useMutation<UpdateTournamentRoomMutation, UpdateTournamentRoomMutationVariables>(UpdateTournamentRoomDocument, baseOptions);
      }
export type UpdateTournamentRoomMutationHookResult = ReturnType<typeof useUpdateTournamentRoomMutation>;
export type UpdateTournamentRoomMutationResult = Apollo.MutationResult<UpdateTournamentRoomMutation>;
export type UpdateTournamentRoomMutationOptions = Apollo.BaseMutationOptions<UpdateTournamentRoomMutation, UpdateTournamentRoomMutationVariables>;
export const BattlesDocument = gql`
    query Battles($tournamentRoomId: ID!) {
  battles(tournamentRoomId: $tournamentRoomId) {
    ...Battle
  }
}
    ${BattleFragmentDoc}`;

/**
 * __useBattlesQuery__
 *
 * To run a query within a React component, call `useBattlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBattlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBattlesQuery({
 *   variables: {
 *      tournamentRoomId: // value for 'tournamentRoomId'
 *   },
 * });
 */
export function useBattlesQuery(baseOptions?: Apollo.QueryHookOptions<BattlesQuery, BattlesQueryVariables>) {
        return Apollo.useQuery<BattlesQuery, BattlesQueryVariables>(BattlesDocument, baseOptions);
      }
export function useBattlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BattlesQuery, BattlesQueryVariables>) {
          return Apollo.useLazyQuery<BattlesQuery, BattlesQueryVariables>(BattlesDocument, baseOptions);
        }
export type BattlesQueryHookResult = ReturnType<typeof useBattlesQuery>;
export type BattlesLazyQueryHookResult = ReturnType<typeof useBattlesLazyQuery>;
export type BattlesQueryResult = Apollo.QueryResult<BattlesQuery, BattlesQueryVariables>;
export const BattleIdDocument = gql`
    query BattleId($id: ID!) {
  battle(id: $id) {
    ...Battle
  }
}
    ${BattleFragmentDoc}`;

/**
 * __useBattleIdQuery__
 *
 * To run a query within a React component, call `useBattleIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useBattleIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBattleIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBattleIdQuery(baseOptions?: Apollo.QueryHookOptions<BattleIdQuery, BattleIdQueryVariables>) {
        return Apollo.useQuery<BattleIdQuery, BattleIdQueryVariables>(BattleIdDocument, baseOptions);
      }
export function useBattleIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BattleIdQuery, BattleIdQueryVariables>) {
          return Apollo.useLazyQuery<BattleIdQuery, BattleIdQueryVariables>(BattleIdDocument, baseOptions);
        }
export type BattleIdQueryHookResult = ReturnType<typeof useBattleIdQuery>;
export type BattleIdLazyQueryHookResult = ReturnType<typeof useBattleIdLazyQuery>;
export type BattleIdQueryResult = Apollo.QueryResult<BattleIdQuery, BattleIdQueryVariables>;
export const CampsDocument = gql`
    query Camps($battleId: ID!) {
  camps(battleId: $battleId) {
    ...Camp
  }
}
    ${CampFragmentDoc}`;

/**
 * __useCampsQuery__
 *
 * To run a query within a React component, call `useCampsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampsQuery({
 *   variables: {
 *      battleId: // value for 'battleId'
 *   },
 * });
 */
export function useCampsQuery(baseOptions?: Apollo.QueryHookOptions<CampsQuery, CampsQueryVariables>) {
        return Apollo.useQuery<CampsQuery, CampsQueryVariables>(CampsDocument, baseOptions);
      }
export function useCampsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampsQuery, CampsQueryVariables>) {
          return Apollo.useLazyQuery<CampsQuery, CampsQueryVariables>(CampsDocument, baseOptions);
        }
export type CampsQueryHookResult = ReturnType<typeof useCampsQuery>;
export type CampsLazyQueryHookResult = ReturnType<typeof useCampsLazyQuery>;
export type CampsQueryResult = Apollo.QueryResult<CampsQuery, CampsQueryVariables>;
export const CampIdDocument = gql`
    query CampId($id: ID!) {
  camp(id: $id) {
    ...Camp
  }
}
    ${CampFragmentDoc}`;

/**
 * __useCampIdQuery__
 *
 * To run a query within a React component, call `useCampIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCampIdQuery(baseOptions?: Apollo.QueryHookOptions<CampIdQuery, CampIdQueryVariables>) {
        return Apollo.useQuery<CampIdQuery, CampIdQueryVariables>(CampIdDocument, baseOptions);
      }
export function useCampIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampIdQuery, CampIdQueryVariables>) {
          return Apollo.useLazyQuery<CampIdQuery, CampIdQueryVariables>(CampIdDocument, baseOptions);
        }
export type CampIdQueryHookResult = ReturnType<typeof useCampIdQuery>;
export type CampIdLazyQueryHookResult = ReturnType<typeof useCampIdLazyQuery>;
export type CampIdQueryResult = Apollo.QueryResult<CampIdQuery, CampIdQueryVariables>;
export const CampPlayersDocument = gql`
    query CampPlayers($campId: ID!) {
  campPlayers(campId: $campId) {
    ...CampPlayer
  }
}
    ${CampPlayerFragmentDoc}`;

/**
 * __useCampPlayersQuery__
 *
 * To run a query within a React component, call `useCampPlayersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampPlayersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampPlayersQuery({
 *   variables: {
 *      campId: // value for 'campId'
 *   },
 * });
 */
export function useCampPlayersQuery(baseOptions?: Apollo.QueryHookOptions<CampPlayersQuery, CampPlayersQueryVariables>) {
        return Apollo.useQuery<CampPlayersQuery, CampPlayersQueryVariables>(CampPlayersDocument, baseOptions);
      }
export function useCampPlayersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampPlayersQuery, CampPlayersQueryVariables>) {
          return Apollo.useLazyQuery<CampPlayersQuery, CampPlayersQueryVariables>(CampPlayersDocument, baseOptions);
        }
export type CampPlayersQueryHookResult = ReturnType<typeof useCampPlayersQuery>;
export type CampPlayersLazyQueryHookResult = ReturnType<typeof useCampPlayersLazyQuery>;
export type CampPlayersQueryResult = Apollo.QueryResult<CampPlayersQuery, CampPlayersQueryVariables>;
export const CampPlayerIdDocument = gql`
    query CampPlayerId($id: ID!) {
  campPlayer(id: $id) {
    ...CampPlayer
  }
}
    ${CampPlayerFragmentDoc}`;

/**
 * __useCampPlayerIdQuery__
 *
 * To run a query within a React component, call `useCampPlayerIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampPlayerIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampPlayerIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCampPlayerIdQuery(baseOptions?: Apollo.QueryHookOptions<CampPlayerIdQuery, CampPlayerIdQueryVariables>) {
        return Apollo.useQuery<CampPlayerIdQuery, CampPlayerIdQueryVariables>(CampPlayerIdDocument, baseOptions);
      }
export function useCampPlayerIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampPlayerIdQuery, CampPlayerIdQueryVariables>) {
          return Apollo.useLazyQuery<CampPlayerIdQuery, CampPlayerIdQueryVariables>(CampPlayerIdDocument, baseOptions);
        }
export type CampPlayerIdQueryHookResult = ReturnType<typeof useCampPlayerIdQuery>;
export type CampPlayerIdLazyQueryHookResult = ReturnType<typeof useCampPlayerIdLazyQuery>;
export type CampPlayerIdQueryResult = Apollo.QueryResult<CampPlayerIdQuery, CampPlayerIdQueryVariables>;
export const ClansDocument = gql`
    query Clans {
  clans {
    ...Clan
  }
}
    ${ClanFragmentDoc}`;

/**
 * __useClansQuery__
 *
 * To run a query within a React component, call `useClansQuery` and pass it any options that fit your needs.
 * When your component renders, `useClansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClansQuery({
 *   variables: {
 *   },
 * });
 */
export function useClansQuery(baseOptions?: Apollo.QueryHookOptions<ClansQuery, ClansQueryVariables>) {
        return Apollo.useQuery<ClansQuery, ClansQueryVariables>(ClansDocument, baseOptions);
      }
export function useClansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClansQuery, ClansQueryVariables>) {
          return Apollo.useLazyQuery<ClansQuery, ClansQueryVariables>(ClansDocument, baseOptions);
        }
export type ClansQueryHookResult = ReturnType<typeof useClansQuery>;
export type ClansLazyQueryHookResult = ReturnType<typeof useClansLazyQuery>;
export type ClansQueryResult = Apollo.QueryResult<ClansQuery, ClansQueryVariables>;
export const GamesDocument = gql`
    query Games {
  games {
    ...Game
  }
}
    ${GameFragmentDoc}`;

/**
 * __useGamesQuery__
 *
 * To run a query within a React component, call `useGamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGamesQuery(baseOptions?: Apollo.QueryHookOptions<GamesQuery, GamesQueryVariables>) {
        return Apollo.useQuery<GamesQuery, GamesQueryVariables>(GamesDocument, baseOptions);
      }
export function useGamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GamesQuery, GamesQueryVariables>) {
          return Apollo.useLazyQuery<GamesQuery, GamesQueryVariables>(GamesDocument, baseOptions);
        }
export type GamesQueryHookResult = ReturnType<typeof useGamesQuery>;
export type GamesLazyQueryHookResult = ReturnType<typeof useGamesLazyQuery>;
export type GamesQueryResult = Apollo.QueryResult<GamesQuery, GamesQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ...BasicJudge
  }
}
    ${BasicJudgeFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const TeamsDocument = gql`
    query Teams($tournamentEventId: ID!, $tournamentTeamState: String, $search: String) {
  teams(tournamentEventId: $tournamentEventId, tournamentTeamState: $tournamentTeamState, search: $search) {
    ...Team
  }
}
    ${TeamFragmentDoc}`;

/**
 * __useTeamsQuery__
 *
 * To run a query within a React component, call `useTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamsQuery({
 *   variables: {
 *      tournamentEventId: // value for 'tournamentEventId'
 *      tournamentTeamState: // value for 'tournamentTeamState'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useTeamsQuery(baseOptions?: Apollo.QueryHookOptions<TeamsQuery, TeamsQueryVariables>) {
        return Apollo.useQuery<TeamsQuery, TeamsQueryVariables>(TeamsDocument, baseOptions);
      }
export function useTeamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamsQuery, TeamsQueryVariables>) {
          return Apollo.useLazyQuery<TeamsQuery, TeamsQueryVariables>(TeamsDocument, baseOptions);
        }
export type TeamsQueryHookResult = ReturnType<typeof useTeamsQuery>;
export type TeamsLazyQueryHookResult = ReturnType<typeof useTeamsLazyQuery>;
export type TeamsQueryResult = Apollo.QueryResult<TeamsQuery, TeamsQueryVariables>;
export const TournamentsDocument = gql`
    query Tournaments($gameId: ID) {
  tournaments(gameId: $gameId) {
    ...ListTournament
  }
}
    ${ListTournamentFragmentDoc}`;

/**
 * __useTournamentsQuery__
 *
 * To run a query within a React component, call `useTournamentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentsQuery({
 *   variables: {
 *      gameId: // value for 'gameId'
 *   },
 * });
 */
export function useTournamentsQuery(baseOptions?: Apollo.QueryHookOptions<TournamentsQuery, TournamentsQueryVariables>) {
        return Apollo.useQuery<TournamentsQuery, TournamentsQueryVariables>(TournamentsDocument, baseOptions);
      }
export function useTournamentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TournamentsQuery, TournamentsQueryVariables>) {
          return Apollo.useLazyQuery<TournamentsQuery, TournamentsQueryVariables>(TournamentsDocument, baseOptions);
        }
export type TournamentsQueryHookResult = ReturnType<typeof useTournamentsQuery>;
export type TournamentsLazyQueryHookResult = ReturnType<typeof useTournamentsLazyQuery>;
export type TournamentsQueryResult = Apollo.QueryResult<TournamentsQuery, TournamentsQueryVariables>;
export const TournamentDocument = gql`
    query Tournament($id: ID!) {
  tournament(id: $id) {
    ...Tournament
  }
}
    ${TournamentFragmentDoc}`;

/**
 * __useTournamentQuery__
 *
 * To run a query within a React component, call `useTournamentQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTournamentQuery(baseOptions?: Apollo.QueryHookOptions<TournamentQuery, TournamentQueryVariables>) {
        return Apollo.useQuery<TournamentQuery, TournamentQueryVariables>(TournamentDocument, baseOptions);
      }
export function useTournamentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TournamentQuery, TournamentQueryVariables>) {
          return Apollo.useLazyQuery<TournamentQuery, TournamentQueryVariables>(TournamentDocument, baseOptions);
        }
export type TournamentQueryHookResult = ReturnType<typeof useTournamentQuery>;
export type TournamentLazyQueryHookResult = ReturnType<typeof useTournamentLazyQuery>;
export type TournamentQueryResult = Apollo.QueryResult<TournamentQuery, TournamentQueryVariables>;
export const TournamentEventsDocument = gql`
    query TournamentEvents($tournamentId: ID!) {
  tournamentEvents(tournamentId: $tournamentId) {
    ...TournamentEvent
  }
}
    ${TournamentEventFragmentDoc}`;

/**
 * __useTournamentEventsQuery__
 *
 * To run a query within a React component, call `useTournamentEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentEventsQuery({
 *   variables: {
 *      tournamentId: // value for 'tournamentId'
 *   },
 * });
 */
export function useTournamentEventsQuery(baseOptions?: Apollo.QueryHookOptions<TournamentEventsQuery, TournamentEventsQueryVariables>) {
        return Apollo.useQuery<TournamentEventsQuery, TournamentEventsQueryVariables>(TournamentEventsDocument, baseOptions);
      }
export function useTournamentEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TournamentEventsQuery, TournamentEventsQueryVariables>) {
          return Apollo.useLazyQuery<TournamentEventsQuery, TournamentEventsQueryVariables>(TournamentEventsDocument, baseOptions);
        }
export type TournamentEventsQueryHookResult = ReturnType<typeof useTournamentEventsQuery>;
export type TournamentEventsLazyQueryHookResult = ReturnType<typeof useTournamentEventsLazyQuery>;
export type TournamentEventsQueryResult = Apollo.QueryResult<TournamentEventsQuery, TournamentEventsQueryVariables>;
export const TournamentEventDocument = gql`
    query TournamentEvent($id: ID!) {
  tournamentEvent(id: $id) {
    ...TournamentEvent
  }
}
    ${TournamentEventFragmentDoc}`;

/**
 * __useTournamentEventQuery__
 *
 * To run a query within a React component, call `useTournamentEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTournamentEventQuery(baseOptions?: Apollo.QueryHookOptions<TournamentEventQuery, TournamentEventQueryVariables>) {
        return Apollo.useQuery<TournamentEventQuery, TournamentEventQueryVariables>(TournamentEventDocument, baseOptions);
      }
export function useTournamentEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TournamentEventQuery, TournamentEventQueryVariables>) {
          return Apollo.useLazyQuery<TournamentEventQuery, TournamentEventQueryVariables>(TournamentEventDocument, baseOptions);
        }
export type TournamentEventQueryHookResult = ReturnType<typeof useTournamentEventQuery>;
export type TournamentEventLazyQueryHookResult = ReturnType<typeof useTournamentEventLazyQuery>;
export type TournamentEventQueryResult = Apollo.QueryResult<TournamentEventQuery, TournamentEventQueryVariables>;
export const TournamentRoomsDocument = gql`
    query TournamentRooms($tournamentEventId: ID!) {
  tournamentRooms(tournamentEventId: $tournamentEventId) {
    ...TournamentRoom
  }
}
    ${TournamentRoomFragmentDoc}`;

/**
 * __useTournamentRoomsQuery__
 *
 * To run a query within a React component, call `useTournamentRoomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentRoomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentRoomsQuery({
 *   variables: {
 *      tournamentEventId: // value for 'tournamentEventId'
 *   },
 * });
 */
export function useTournamentRoomsQuery(baseOptions?: Apollo.QueryHookOptions<TournamentRoomsQuery, TournamentRoomsQueryVariables>) {
        return Apollo.useQuery<TournamentRoomsQuery, TournamentRoomsQueryVariables>(TournamentRoomsDocument, baseOptions);
      }
export function useTournamentRoomsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TournamentRoomsQuery, TournamentRoomsQueryVariables>) {
          return Apollo.useLazyQuery<TournamentRoomsQuery, TournamentRoomsQueryVariables>(TournamentRoomsDocument, baseOptions);
        }
export type TournamentRoomsQueryHookResult = ReturnType<typeof useTournamentRoomsQuery>;
export type TournamentRoomsLazyQueryHookResult = ReturnType<typeof useTournamentRoomsLazyQuery>;
export type TournamentRoomsQueryResult = Apollo.QueryResult<TournamentRoomsQuery, TournamentRoomsQueryVariables>;
export const TournamentRoomIdDocument = gql`
    query TournamentRoomId($id: ID!) {
  me {
    ...BasicJudge
  }
  tournamentRoom(id: $id) {
    ...TournamentRoom
  }
}
    ${BasicJudgeFragmentDoc}
${TournamentRoomFragmentDoc}`;

/**
 * __useTournamentRoomIdQuery__
 *
 * To run a query within a React component, call `useTournamentRoomIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentRoomIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentRoomIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTournamentRoomIdQuery(baseOptions?: Apollo.QueryHookOptions<TournamentRoomIdQuery, TournamentRoomIdQueryVariables>) {
        return Apollo.useQuery<TournamentRoomIdQuery, TournamentRoomIdQueryVariables>(TournamentRoomIdDocument, baseOptions);
      }
export function useTournamentRoomIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TournamentRoomIdQuery, TournamentRoomIdQueryVariables>) {
          return Apollo.useLazyQuery<TournamentRoomIdQuery, TournamentRoomIdQueryVariables>(TournamentRoomIdDocument, baseOptions);
        }
export type TournamentRoomIdQueryHookResult = ReturnType<typeof useTournamentRoomIdQuery>;
export type TournamentRoomIdLazyQueryHookResult = ReturnType<typeof useTournamentRoomIdLazyQuery>;
export type TournamentRoomIdQueryResult = Apollo.QueryResult<TournamentRoomIdQuery, TournamentRoomIdQueryVariables>;
export const TournamentTeamAccountsDocument = gql`
    query TournamentTeamAccounts($tournamentId: ID, $state: String, $page: Int!) {
  me {
    ...BasicJudge
  }
  tournamentTeamAccounts(tournamentId: $tournamentId, state: $state, page: $page) {
    count
    data {
      ...TournamentTeam
    }
  }
}
    ${BasicJudgeFragmentDoc}
${TournamentTeamFragmentDoc}`;

/**
 * __useTournamentTeamAccountsQuery__
 *
 * To run a query within a React component, call `useTournamentTeamAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentTeamAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentTeamAccountsQuery({
 *   variables: {
 *      tournamentId: // value for 'tournamentId'
 *      state: // value for 'state'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useTournamentTeamAccountsQuery(baseOptions?: Apollo.QueryHookOptions<TournamentTeamAccountsQuery, TournamentTeamAccountsQueryVariables>) {
        return Apollo.useQuery<TournamentTeamAccountsQuery, TournamentTeamAccountsQueryVariables>(TournamentTeamAccountsDocument, baseOptions);
      }
export function useTournamentTeamAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TournamentTeamAccountsQuery, TournamentTeamAccountsQueryVariables>) {
          return Apollo.useLazyQuery<TournamentTeamAccountsQuery, TournamentTeamAccountsQueryVariables>(TournamentTeamAccountsDocument, baseOptions);
        }
export type TournamentTeamAccountsQueryHookResult = ReturnType<typeof useTournamentTeamAccountsQuery>;
export type TournamentTeamAccountsLazyQueryHookResult = ReturnType<typeof useTournamentTeamAccountsLazyQuery>;
export type TournamentTeamAccountsQueryResult = Apollo.QueryResult<TournamentTeamAccountsQuery, TournamentTeamAccountsQueryVariables>;
export const TournamentTeamIdDocument = gql`
    query TournamentTeamId($id: ID!) {
  me {
    ...BasicJudge
  }
  tournamentTeam(id: $id) {
    ...TournamentTeam
  }
}
    ${BasicJudgeFragmentDoc}
${TournamentTeamFragmentDoc}`;

/**
 * __useTournamentTeamIdQuery__
 *
 * To run a query within a React component, call `useTournamentTeamIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentTeamIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentTeamIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTournamentTeamIdQuery(baseOptions?: Apollo.QueryHookOptions<TournamentTeamIdQuery, TournamentTeamIdQueryVariables>) {
        return Apollo.useQuery<TournamentTeamIdQuery, TournamentTeamIdQueryVariables>(TournamentTeamIdDocument, baseOptions);
      }
export function useTournamentTeamIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TournamentTeamIdQuery, TournamentTeamIdQueryVariables>) {
          return Apollo.useLazyQuery<TournamentTeamIdQuery, TournamentTeamIdQueryVariables>(TournamentTeamIdDocument, baseOptions);
        }
export type TournamentTeamIdQueryHookResult = ReturnType<typeof useTournamentTeamIdQuery>;
export type TournamentTeamIdLazyQueryHookResult = ReturnType<typeof useTournamentTeamIdLazyQuery>;
export type TournamentTeamIdQueryResult = Apollo.QueryResult<TournamentTeamIdQuery, TournamentTeamIdQueryVariables>;
export const TournamentTeamCsvDocument = gql`
    query TournamentTeamCsv($tournamentId: ID!) {
  tournamentTeamCsv(tournamentId: $tournamentId)
}
    `;

/**
 * __useTournamentTeamCsvQuery__
 *
 * To run a query within a React component, call `useTournamentTeamCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentTeamCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentTeamCsvQuery({
 *   variables: {
 *      tournamentId: // value for 'tournamentId'
 *   },
 * });
 */
export function useTournamentTeamCsvQuery(baseOptions?: Apollo.QueryHookOptions<TournamentTeamCsvQuery, TournamentTeamCsvQueryVariables>) {
        return Apollo.useQuery<TournamentTeamCsvQuery, TournamentTeamCsvQueryVariables>(TournamentTeamCsvDocument, baseOptions);
      }
export function useTournamentTeamCsvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TournamentTeamCsvQuery, TournamentTeamCsvQueryVariables>) {
          return Apollo.useLazyQuery<TournamentTeamCsvQuery, TournamentTeamCsvQueryVariables>(TournamentTeamCsvDocument, baseOptions);
        }
export type TournamentTeamCsvQueryHookResult = ReturnType<typeof useTournamentTeamCsvQuery>;
export type TournamentTeamCsvLazyQueryHookResult = ReturnType<typeof useTournamentTeamCsvLazyQuery>;
export type TournamentTeamCsvQueryResult = Apollo.QueryResult<TournamentTeamCsvQuery, TournamentTeamCsvQueryVariables>;